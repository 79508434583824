import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Card, CardHeader, Col, Row, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import username from 'src/util/decorators/username';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminPropertyUserShow component
 */
function AdminPropertyUserShow(props) {
  if (!props) {
    return <Loading />;
  }

  const { propertyUser } = props;
  const {
    id, externalIdentifier, createdAt, updatedAt,
    active, property, user,
  } = propertyUser;
  const { start, finish } = active;
  const {
    id: propertyId, title: propertyTitle, active: propertyActive,
    externalIdentifier: propertyExternalIdentifier,
  } = property;
  const {
    id: userId, externalIdentifier: userExternalIdentifier,
    email: userEmail, active: userActive,
  } = user;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Enosi Admin - Property User - ${id}`}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Property Users', path: '/admin/property-users' },
          { name: `Property User: ${id}` },
        ]}
      />

      <h1>
        {id}
      </h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <Card className="mt-4 mb-4">
            <CardHeader>
              Property user details
            </CardHeader>
            <Table responsive>
              <tbody>
                <tr>
                  <th scope="row" className="text-right">Active status</th>
                  <td>
                    <BadgeActive start={start} finish={finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">ID</th>
                  <td>
                    <UUID uuid={id} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <td>{externalIdentifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Start</th>
                  <td>
                    {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Finish</th>
                  <td>
                    {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Created at</th>
                  <td>
                    {createdAt
                      && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Updated at</th>
                  <td>
                    {updatedAt
                      && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={8}>
          <Card className="mt-4 mb-4">
            <CardHeader>
              Property
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">ID</th>
                  <th scope="col">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <th scope="col">Active</th>
                </tr>
              </thead>
              <tbody>
                <tr key={`property-user-property-${propertyId}`}>
                  <th scope="row" colSpan={2}>
                    <Link to={`/admin/properties/${propertyId}`}>
                      {propertyTitle}
                    </Link>
                    <br />
                    <UUID uuid={propertyId} />
                  </th>
                  <td>
                    {propertyExternalIdentifier}
                  </td>
                  <td>
                    <BadgeActive start={propertyActive.start} finish={propertyActive.finish} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>

          <Card className="mt-4 mb-4">
            <CardHeader>
              User
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Username</th>
                  <th scope="col">Email</th>
                  <th scope="col">ID</th>
                  <th scope="col">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <th scope="col">Active</th>
                </tr>
              </thead>
              <tbody>
                <tr key={`property-user-user-${userId}`}>
                  <th scope="row" colSpan={2}>
                    <Link to={`/admin/users/${userId}`}>
                      {username(user)}
                    </Link>
                    <br />
                    {userEmail}
                  </th>
                  <td colSpan={2}>
                    <UUID uuid={userId} />
                    <br />
                    {userExternalIdentifier}
                  </td>
                  <td>
                    <BadgeActive start={userActive.start} finish={userActive.finish} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}

AdminPropertyUserShow.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  propertyUser: PropTypes.shape({
    id: PropTypes.string,
    externalIdentifier: PropTypes.string,
    createdAt: PropTypes.number,
    updatedAt: PropTypes.number,
    active: PropTypes.shape({
      start: PropTypes.number,
      finish: PropTypes.number,
    }),
    property: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      externalIdentifier: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
      address: PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postcode: PropTypes.string,
      }),
    }),
    user: PropTypes.shape({
      id: PropTypes.string,
      externalIdentifier: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
  }),
};

AdminPropertyUserShow.defaultProps = {
  propertyUser: null,
};

export default createFragmentContainer(
  AdminPropertyUserShow,
  {
    propertyUser: graphql`
      fragment AdminPropertyUserShow_propertyUser on PropertyUser {
        id
        externalIdentifier
        createdAt
        updatedAt
        active { start finish }
        property {
          id
          title
          externalIdentifier
          active { start finish }
        }
        user {
          id
          externalIdentifier
          email
          givenName
          familyName
          active { start finish }
        }
      }
    `,
  },
);
