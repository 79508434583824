import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import username from 'src/util/decorators/username';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminPropertyList component
 */
function AdminPropertyShowPropertyUser(props) {
  if (!props) {
    return <Loading />;
  }

  const { propertyUser } = props;
  const {
    id, externalIdentifier, active, user,
  } = propertyUser;
  const { start, finish } = active;
  const {
    id: userId, externalIdentifier: userExternalIdentifier, email: userEmail, active: userActive,
  } = user;

  return (
    <tr>
      <th scope="row" className="text-right">
        <Link to={`/admin/property-users/${id}`}>
          <UUID uuid={id} />
        </Link>
      </th>
      <td>
        <BadgeActive start={start} finish={finish} />
        <br />
        {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
        -
        {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
      </td>
      <td>{externalIdentifier}</td>
      <th scope="row" colSpan={2}>
        <Link to={`/admin/users/${userId}`}>
          {username(user)}
          <br />
          {userEmail}
        </Link>
      </th>
      <td>
        <BadgeActive start={userActive.start} finish={userActive.finish} />
        <br />
        {userActive.start
          && DateTime.fromSeconds(userActive.start).toLocaleString(DateTime.DATETIME_FULL)}
        -
        {userActive.finish
          && DateTime.fromSeconds(userActive.finish).toLocaleString(DateTime.DATETIME_FULL)}
      </td>
      <td colSpan={2}>
        <UUID uuid={userId} />
        <br />
        {userExternalIdentifier}
      </td>
    </tr>
  );
}

AdminPropertyShowPropertyUser.propTypes = {
  propertyUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminPropertyShowPropertyUser.defaultProps = {
  propertyUser: null,
};

export default AdminPropertyShowPropertyUser;
