import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createPaginationContainer, graphql } from 'react-relay';
import {
  Button, Card, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

import AdminCommunityShowProperty from './AdminCommunityShowProperty';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminCommunityShow component
 */
function AdminCommunityShow(props) {
  const loadMore = () => {
    const { relay } = props;

    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }

    relay.loadMore(
      50,
      (error) => { console.log(error); },
    );
  };

  if (!props) {
    return <Loading />;
  }

  const { relay, community } = props;
  const {
    id, externalIdentifier, active, createdAt,
    updatedAt, title, properties,
  } = community;
  const { start, finish } = active;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Enosi Admin - Community - ${title}`}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Communities', path: '/admin/communities' },
          { name: title },
        ]}
      />

      <h1>
        {title}
      </h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
              Community details
            </CardHeader>
            <Table responsive>
              <tbody>
                <tr>
                  <th scope="row" className="text-right">Active status</th>
                  <td>
                    <BadgeActive start={start} finish={finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">ID</th>
                  <td>
                    <UUID uuid={id} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <td>{externalIdentifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Start</th>
                  <td>
                    {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Finish</th>
                  <td>
                    {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Title</th>
                  <td>{title}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Created at</th>
                  <td>
                    {createdAt
                      && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Updated at</th>
                  <td>
                    {updatedAt
                      && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={8}>
          <Card>
            <CardHeader>
              Properties
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">ID</th>
                  <th scope="col">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <th scope="col">Active</th>
                </tr>
              </thead>
              <tbody>
                {properties && properties.edges.map((el) => (
                  <AdminCommunityShowProperty property={el.node} key={el.node?.id} />
                ))}
              </tbody>
            </Table>
            <CardFooter>
              <Button onClick={loadMore} disabled={!relay.hasMore()}>Load more...</Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
}

AdminCommunityShow.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  community: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminCommunityShow.defaultProps = {
  community: null,
};

export default createPaginationContainer(
  AdminCommunityShow,
  {
    community: graphql`
      fragment AdminCommunityShow_community on Community {
        id
        externalIdentifier
        title
        createdAt
        updatedAt
        active { start finish }
        properties(
          first: $count
          after: $cursor
        ) @connection(key: "AdminCommunityShow_properties") {
          edges {
            node {
              id
              title
              externalIdentifier
              active { start finish }
              address {
                line1 line2 city state postcode
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.community.properties;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        id: props.community.id,
      };
    },
    query: graphql`
      # Pagination query to be fetched upon calling 'loadMore'.
      # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
      query AdminCommunityShow_Pagination_Query(
        $id: ID!
        $count: Int!
        $cursor: String
      ) {
        community(id: $id) {
          ...AdminCommunityShow_community
        }
      }
    `,
  },
);
