import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Card, CardHeader, Col, Row, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import BadgeUserType from 'src/components/Badge/BadgeUserType';
import Loading from 'src/components/Loading';
// import Timestamp from 'src/components/Time/Timestamp';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import username from 'src/util/decorators/username';

import AdminUserShowPropertyUser from './AdminUserShowPropertyUser';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminUserShow component
 */
function AdminUserShow(props) {
  if (!props) {
    return <Loading />;
  }

  const { user } = props;
  const {
    id, email, givenName, familyName, externalIdentifier, type, active,
    createdAt, updatedAt, userProperties,
  } = user;
  const { start, finish } = active;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Enosi Admin - User - ${username(user)}`}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Users', path: '/admin/users' },
          { name: `User: ${username(user)}` },
        ]}
      />

      <h1>
        {username(user)}
      </h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
              User details
            </CardHeader>
            <Table responsive>
              <tbody>
                <tr>
                  <th scope="row" className="text-right">Active status</th>
                  <td>
                    <BadgeActive start={start} finish={finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">ID</th>
                  <td>
                    <UUID uuid={id} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Type</th>
                  <td>
                    <BadgeUserType type={type} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <td>{externalIdentifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Email</th>
                  <td>{email}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Given name</th>
                  <td>{givenName}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Family name</th>
                  <td>{familyName}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Unverified email</th>
                  <td>To be exposed</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Start</th>
                  <td>
                    {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Finish</th>
                  <td>
                    {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Created at</th>
                  <td>
                    {createdAt
                      && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Updated at</th>
                  <td>
                    {updatedAt
                      && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={8}>
          <Card>
            <CardHeader>
              User properties
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col" colSpan={3} className="text-center">PropertyUser</th>
                  <th scope="col" colSpan={4} className="text-center">Property</th>
                </tr>
                <tr>
                  <th scope="col">
                    <abbr title="Identifier">ID</abbr>
                  </th>
                  <th scope="col">Active</th>
                  <th scope="col">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <th scope="col">Title</th>
                  <th scope="col">
                    <abbr title="Identifier">ID</abbr>
                  </th>
                  <th scope="col">Active</th>
                  <th scope="col">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                </tr>
              </thead>
              <tbody>
                {userProperties && userProperties.edges.map((el) => (
                  <AdminUserShowPropertyUser propertyUser={el.node} key={el.node?.id} />
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}

AdminUserShow.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  user: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminUserShow.defaultProps = {
  user: null,
};

export default createFragmentContainer(
  AdminUserShow,
  {
    user: graphql`
      fragment AdminUserShow_user on User {
        id
        externalIdentifier
        type
        email
        givenName
        familyName
        active { start finish }
        createdAt
        updatedAt
        userProperties(first: 500) {
          edges {
            node {
              id
              externalIdentifier
              active { start finish }
              property { id externalIdentifier title active { start finish } }
            }
          }
        }
      }
    `,
  },
);
