import React from 'react';
import { FormattedMessage } from 'react-intl';
/**
 * Generates a subheading string with the number of properties and users.
 * @param {number} count - The number of properties.
 * @returns {React.ReactElement} A subheading string
 */
const subHeadingProperties = (count) => (
  <FormattedMessage
    id="portfolio.portfolio_list.portfolios_cards.description.properties"
    defaultMessage="{count, plural, =0 {no properties} one {# property} other {# properties}}"
    values={{ count }}
  />
);

const subHeadingUsers = (count) => (
  <FormattedMessage
    id="portfolio.portfolio_list.portfolios_cards.description.users"
    defaultMessage="{count, plural, =0 {no users} one {# user} other {# users}}"
    values={{ count }}
  />
);

/**
 * Generates a subheading string with the number of properties and users.
 * @param {number} propertyCount - The number of properties.
 * @param {number} userCount - The number of users.
 * @returns {React.ReactElement} A subheading string
 */

const subHeading = (propertyCount, userCount) => (
  <>
    {subHeadingProperties(propertyCount)}
    {' '}
    •
    {' '}
    {subHeadingUsers(userCount)}
  </>
);

export default subHeading;
