import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import FlashesStore from 'src/stores/FlashesStore';
import { USER_TYPE_CUSTOMER } from 'src/util/constants';

import { getTradeRules, tradeRulesBuy, tradeRulesSell } from './helpers';

class TradeRuleCustomerProposals extends React.Component {
  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, router, viewer } = this.props;
    // eslint-disable-next-line react/prop-types
    const { proposals, viewerUser } = viewer;
    const { id } = viewerUser;

    if (!viewerUser || viewerUser.type !== USER_TYPE_CUSTOMER) {
      // TODO: Not all flashes have been i18n yet.
      FlashesStore.flash(FlashesStore.ERROR, <FormattedMessage id="trade_rule_customer_proposals.view.restriction.text" defaultMessage="only customers can view their proposals" />);
      router.replace('/properties');
    }

    const tradeRules = getTradeRules(id, proposals);
    const {
      buy = [], counterPartyBuy = [], counterPartySell = [], sell = [],
    } = tradeRules || {};

    const totalBuys = buy.length + counterPartySell.length;
    const totalSells = sell.length + counterPartyBuy.length;

    const pageTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_customer_proposals.page.title', defaultMessage: 'Enosi - Manage my proposals' });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: <FormattedMessage id="trade_rule.trade_rule_customer_proposals.breadcrumbs.trade_rules.label" defaultMessage="Trade Rules" />, path: '/trade-rules' },
            { name: <FormattedMessage id="trade_rule.trade_rule_customer_proposals.breadcrumbs.proposals.label" defaultMessage="Proposals" /> },
          ]}
        />
        <Heading
          alias={<FormattedMessage id="trade_rule.trade_rule_customer_proposals.heading.alias.trade_rules.label" defaultMessage="Trade Rules" />}
          context={<FormattedMessage id="trade_rule.trade_rule_customer_proposals.heading.context.proposals.label" defaultMessage="Proposals" />}
        />

        <Card className="mt-4 mb-4 trade-proposals">
          <CardBody>
            <h2><FormattedMessage id="trade_rule.trade_rule_customer_proposals.proposed_trades.title" defaultMessage="Proposed Trades" /></h2>
            <Row>
              <Col xs="12" sm="12" md="6">
                <h3><FormattedMessage id="trade_rule.trade_rule_customer_proposals.selling.label" defaultMessage="Selling" /></h3>
                <p data-testid="selling">
                  <FormattedMessage
                    id="trade_rule.trade_rule_customer_proposals.selling.text"
                    defaultMessage="You have {totalSells} proposed trade rules to sell energy."
                    values={{
                      totalSells: <strong>{totalSells}</strong>,
                    }}
                  />
                </p>
                {tradeRulesSell(intl, sell, viewer)}
                {tradeRulesBuy(intl, counterPartyBuy, viewer)}
              </Col>

              <Col xs="12" sm="12" md="6">
                <h3><FormattedMessage id="trade_rule.trade_rule_customer_proposals.buying.label" defaultMessage="Buying" /></h3>
                <p data-testid="buying">
                  <FormattedMessage
                    id="trade_rule.trade_rule_customer_proposals.buying.text"
                    defaultMessage="You have {totalBuys} proposed trade rules to buy energy."
                    values={{
                      totalBuys: <strong>{totalBuys}</strong>,
                    }}
                  />
                </p>
                {tradeRulesBuy(intl, buy, viewer)}
                {tradeRulesSell(intl, counterPartySell, viewer)}
              </Col>

            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

TradeRuleCustomerProposals.propTypes = {
  viewer: PropTypes.shape({
    viewerUser: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    }),
    meters: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            identifier: PropTypes.string,
            tradePointId: PropTypes.string,
            active: PropTypes.shape({
              start: PropTypes.number,
              finish: PropTypes.number,
            }),
          }),
        }),
      ),
    }),
    proposals: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
          }),
        }),
      ),
    }),
  }).isRequired, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
};

TradeRuleCustomerProposals.defaultProps = {};

export default injectIntl(createFragmentContainer(
  TradeRuleCustomerProposals,
  {
    viewer: graphql`
      fragment TradeRuleCustomerProposals_viewer on Viewer {
        id
        viewerUser { id type }
        meters(first: 500) {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              active { start finish }
              property {
                id
                title
                active { start finish }
              }
            }
          }
        }
        proposals: rules(first:500, type: TRADE_TYPE_NOMINATED, state: TRADE_RULE_STATE_PROPOSED) {
          edges {
            node {
              id
              clauses {
                edges {
                  node {
                    price
                  }
                }
              }
              tradeType
              state
              buyer {
                user { id email givenName familyName }
                tradePoint { id type }
              }
              seller {
                user { id email givenName familyName }
                tradePoint { id type }
              }
              proposedAt
              proposedBy { id email givenName familyName }
            }
          }
        }
      }
    `,
  },
));
