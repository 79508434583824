import { format } from 'd3-format';

import {
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CLOSED,
  TRADE_TYPE_RESIDUAL, TRADE_VALUE_BUY_BENEFIT, TRADE_VALUE_BUY_COST,
  TRADE_VALUE_NEUTRAL, TRADE_VALUE_SELL_BENEFIT, TRADE_VALUE_SELL_COST,
} from './constants';
import { isTradeRuleFlat } from './helpers';

/**
 * Identifies the complimentary residual trade rule that is to be used for calculating
 * the counterfactual trade set for a given trade set. Note we use the first valid residual
 * rule when ordered by `acceptedAt` `ASC`. This reflects the methodology used in the trade
 * engine.
 * @param {object} tradeSetSummary For which we are going to calculate the counterfactual.
 * @param {Array<object>} allRules An array of all trade rules for a given time frame.
 * @returns {object | null} The residual trade rule that is applicable for counterfactual
 * calculation.
 */
export const getComplimentaryResidualRuleFor = (tradeSetSummary, allRules) => {
  if (!tradeSetSummary || !allRules) {
    return null;
  }
  const {
    direction, tradePointId, range,
  } = tradeSetSummary;
  const { start, finish } = range || {};
  const isBuy = direction === TRADE_DIRECTION_BUY;
  const isSell = direction === TRADE_DIRECTION_SELL;

  // Get the list of active residual trades that has
  // overlap with the current trade trade set summary's  range.
  const validRules = allRules.filter(
    (rule) => {
      const { buyer, seller } = rule;
      const { tradePoint: buyerTradePoint } = buyer;
      const { tradePoint: sellerTradePoint } = seller;

      const isResidual = rule.tradeType === TRADE_TYPE_RESIDUAL;
      const isValidState = [
        TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CLOSED,
      ].indexOf(rule.state) !== -1;
      const ruleBeforeRange = (rule.finish && rule.finish <= start);
      const ruleAfterRange = (rule.start && rule.start >= finish);
      const isValidActivePeriod = !(ruleBeforeRange || ruleAfterRange);

      const isBuyer = isBuy && buyerTradePoint && tradePointId === buyerTradePoint.id;
      const isSeller = isSell && sellerTradePoint && tradePointId === sellerTradePoint.id;
      const isCorrectActor = isBuyer || isSeller;

      return isResidual && isValidState && isValidActivePeriod && isCorrectActor;
    },
  ).sort((a, b) => a.acceptedAt - b.acceptedAt);

  if (validRules?.length === 0) {
    return null;
  }

  return validRules[0];
};

/**
 * Returns the i18n label for the cost benefit of a trade.
 * @param {object} intl - i18n object
 * @param {number} value
 * @param {TRADE_VALUE_BUY_BENEFIT | TRADE_VALUE_SELL_BENEFIT
 * | TRADE_VALUE_NEUTRAL | TRADE_VALUE_BUY_COST
 * | TRADE_VALUE_SELL_COST} state
 * @returns {string | null} A cost benefit label.
 */
export const costBenefitLabel = (intl, value, state) => {
  if (Number.isNaN(value)) {
    return null;
  }

  switch (state) {
    case TRADE_VALUE_BUY_BENEFIT:
    case TRADE_VALUE_SELL_BENEFIT:
      return intl.formatMessage({ id: 'trade_summary.benefit.label', defaultMessage: 'Benefit of {n, number, ::currency/AUD}' }, { n: format('.2f')(value) });

    case TRADE_VALUE_NEUTRAL:
      return intl.formatMessage({ id: 'trade_summary.neutral.label', defaultMessage: 'No change' });
    case TRADE_VALUE_BUY_COST:
    case TRADE_VALUE_SELL_COST:
      return intl.formatMessage({ id: 'trade_summary.cost.label', defaultMessage: 'Cost of {n, number, ::currency/AUD}' }, { n: format('.2f')(value) });
    default:
      return null;
  }
};
/**
 * When buying, if you pay less than the alternative you have a benefit. When
 * selling, if you sell for more than the alternative you have a benefit.
 * @param {number} netValueDiff
 * @param {TRADE_DIRECTION_BUY | TRADE_DIRECTION_SELL} direction
 * @returns {object | null} an object with keys for is benefit, cost and neutral.
 */
export const netValueDiffState = (netValueDiff, direction) => {
  if ((netValueDiff !== 0 && !netValueDiff) || !direction) {
    return null;
  }
  const buyBenefit = direction === TRADE_DIRECTION_BUY && netValueDiff < 0;
  const buyCost = direction === TRADE_DIRECTION_BUY && netValueDiff > 0;
  const sellbenefit = direction === TRADE_DIRECTION_SELL && netValueDiff > 0;
  const sellCost = direction === TRADE_DIRECTION_SELL && netValueDiff < 0;

  return {
    TRADE_VALUE_BUY_BENEFIT: buyBenefit,
    TRADE_VALUE_SELL_BENEFIT: sellbenefit,
    TRADE_VALUE_BUY_COST: buyCost,
    TRADE_VALUE_SELL_COST: sellCost,
    TRADE_VALUE_NEUTRAL: !(sellbenefit || buyBenefit || sellCost || buyCost),
  };
};

/**
 * Shows counterfactual cost benefit on property dashboard.
 * This is applicable only for flat price trades (that is, length 1, all time).
 * @param {object} tradeSetSummary - the trade set summary
 * that we are determining the counterfactual for.
 * @param {Array<object>} allRules - list of all trade rules for a given time frame.
 * @returns {number} cost benefit for the user. Returns NaN if not a number.
 */
export const getCounterFactualValue = (tradeSetSummary, allRules) => {
  if (!tradeSetSummary || !allRules || allRules.length === 0) {
    return NaN;
  }

  const residualRule = getComplimentaryResidualRuleFor(tradeSetSummary, allRules);
  if (!residualRule) {
    return NaN;
  }

  if (!isTradeRuleFlat(residualRule)) {
    return NaN;
  }

  const residualPrice = residualRule.clauses?.edges[0]?.node?.price;

  if (residualPrice === null) {
    return NaN;
  }

  return tradeSetSummary.volume * residualPrice;
};
