import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedPlural, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Alert, Badge, Button, Card, CardBody, CardFooter, CardSubtitle, CardText, CardTitle, Col, Row,
} from 'reactstrap';

import Loading from 'src/components/Loading';
import { buyPriceInformation, sellPriceInformation } from 'src/components/TradeRule/TradeRuleHelpers';
import { APIConfig } from 'src/config';
import Time from 'src/enosikit/components/Time/components/Time';
import CancelNominatedTradeMutation from 'src/mutations/CancelNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import {
  TRADE_RULE_CANCEL, TRADE_RULE_STATE_PROPOSED,
} from 'src/util/constants';
import username from 'src/util/decorators/username';
import { getStringAndNumericFormattedDate } from 'src/util/i18n/helpers';
import { tradeRulePriceRange } from 'src/util/tradeRule';

class TradeRuleActiveProposed extends React.Component {
  static tradeRulesForProperty(property) {
    const { meters } = property;
    const rules = { sell: [], buy: [], count: 0 };

    meters.edges.forEach((meterEdge) => {
      if (!!meterEdge && !!meterEdge.node && !!meterEdge.node.proposedRules) {
        meterEdge.node.proposedRules.edges.forEach((ruleEdge) => {
          const { node: ruleNode } = ruleEdge;
          if (ruleNode.state !== TRADE_RULE_STATE_PROPOSED) return;

          if (ruleNode.seller.tradePoint.id === meterEdge.node.tradePointId) {
            rules.sell.push({ ...ruleNode, meter: meterEdge.node });
          } else {
            rules.buy.push({ ...ruleNode, meter: meterEdge.node });
          }
        });
      }
    });
    rules.count = rules.buy.length + rules.sell.length;

    return rules;
  }

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleNominatedTradeMutationFailure = (error) => {
    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleCancelNominatedTradeMutationSuccess = (response) => {
    const { id } = response.cancelNominatedTrade;
    const { intl } = this.props;
    const successMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_proposed.response_message.success', defaultMessage: 'Cancelled peer-to-peer trade rule proposal with id: {id}.' }, { id });

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      successMsg,
    );
  };

  tradeRuleAction = (rule, action) => {
    const { id } = rule;
    const { intl } = this.props;
    const errorMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_proposed.response_message.error', defaultMessage: 'Action {action} is not possible.' }, { action });
    switch (action) {
      case TRADE_RULE_CANCEL:
        CancelNominatedTradeMutation(
          id,
          this.handleCancelNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
        );
        break;
      default:

        FlashesStore.flash(
          FlashesStore.ERROR,
          errorMsg,
        );
    }
  };

  tradeRuleActionButton = (rule) => {
    const {
      state, meter, buyer, seller,
    } = rule;

    if (state !== TRADE_RULE_STATE_PROPOSED) {
      return null;
    }

    if (
      [buyer.tradePoint.id, seller.tradePoint.id].includes(meter.tradePointId)
    ) {
      return (
        <Button onClick={() => this.tradeRuleAction(rule, TRADE_RULE_CANCEL)}>
          Cancel proposed trade
        </Button>
      );
    }

    return (
      <Alert color="danger">
        <FormattedMessage
          id="trade_rule.trade_rule_active.trade_rule_proposed.unknown_trade_point.alert"
          defaultMessage="Unknown trade point: {meterId} when buyer is {buyerTradePointId} and seller is {sellerTradePointId}"
          values={{
            meterId: meter.id,
            buyerTradePointId: buyer.tradePoint.id,
            sellerTradePointId: seller.tradePoint.id,
          }}
        />
      </Alert>
    );
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, property } = this.props;
    const { formatMessage } = intl;
    const tradeRules = TradeRuleActiveProposed.tradeRulesForProperty(property);

    if (tradeRules.count === 0) {
      return null;
    }

    const singleProposal = formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_proposed.proposal.singular', defaultMessage: 'proposed trade rule' });
    const multipleProposal = formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_proposed.proposal.multiple', defaultMessage: 'proposed trade rules' });
    const buyProposalCount = tradeRules.buy.length;
    const sellProposalCount = tradeRules.sell.length;

    return (
      <Card className="mt-4 mb-4 trade-rules-proposed">
        <CardBody>
          <h2><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.proposed_trades.label" defaultMessage="Proposed Trades" /></h2>
          <p><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.awaiting.response.help_text" defaultMessage="We are waiting on your friends to get back in touch." /></p>

          <Row>
            <Col xs="12" sm="12" md="6">
              <h3><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.selling.title" defaultMessage="Selling" /></h3>
              <FormattedMessage
                id="trade_rule.trade_rule_active.trade_rule_proposed.sell.count_details"
                defaultMessage="You have {proposalCount} {proposalCountLabel} to sell energy"
                values={{
                  proposalCount: sellProposalCount,
                  proposalCountLabel: <FormattedPlural
                    value={sellProposalCount}
                    one={singleProposal}
                    other={multipleProposal}
                  />,
                }}
              />

              {tradeRules.sell && tradeRules.sell.map((rule) => {
                const priceRange = tradeRulePriceRange(rule) || {};
                const { minimum, maximum } = priceRange;

                const tradePointTitle = rule.meter.title;

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${rule.id}`}>
                    <CardBody>
                      <CardTitle tag="h4">{tradePointTitle}</CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.sell.proposed.label" defaultMessage="Proposed" />
                      </CardSubtitle>
                      <CardText>
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_proposed.sell.proposal.actors_detail"
                          defaultMessage="{counterparty} has proposed to sell to {actor}"
                          values={{
                            actor: <strong>{username(rule.buyer.user)}</strong>,
                            counterparty: <strong>{username(rule.seller.user)}</strong>,
                          }}
                        />
                        <br />
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_proposed.sell.proposal.time_detail"
                          defaultMessage="Proposed by {actor} on {timestamp}"
                          values={{
                            actor: <strong>{username(rule.proposedBy)}</strong>,
                            timestamp: <Time
                              child={getStringAndNumericFormattedDate(intl, rule.proposedAt)}
                              unixTimestamp={rule.proposedAt}
                            />,
                          }}
                        />
                        <br />
                        {
                          sellPriceInformation({
                            mode: APIConfig().MODE,
                            minimum,
                            maximum,
                            detailsLink: <Link to={`/properties/${property.id}/trade-rules/${rule.id}`}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.buy.further_details" defaultMessage="further details" /></Link>,
                            units: <abbr title={formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_proposed.sell.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.sell.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
                          })
                        }
                        <br />
                        <Link
                          to={`/properties/${property.id}/trade-rules/${rule.id}`}
                        >
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                    <CardFooter>{this.tradeRuleActionButton(rule)}</CardFooter>
                  </Card>
                );
              })}
            </Col>

            <Col xs="12" sm="12" md="6">
              <h3><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.buying.title" defaultMessage="Buying" /></h3>
              <FormattedMessage
                id="trade_rule.trade_rule_active.trade_rule_proposed.buy.count_details"
                defaultMessage="You have {proposalCount} {proposalCountLabel} to buy energy"
                values={{
                  proposalCount: buyProposalCount,
                  proposalCountLabel: <FormattedPlural
                    value={buyProposalCount}
                    one={singleProposal}
                    other={multipleProposal}
                  />,
                }}
              />

              {tradeRules.buy && tradeRules.buy.map((rule) => {
                const priceRange = tradeRulePriceRange(rule);
                const { minimum, maximum } = priceRange;
                const tradePointTitle = rule.meter.title;

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${rule.id}`}>
                    <CardBody>
                      <CardTitle tag="h4">{tradePointTitle}</CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.buy.proposed.label" defaultMessage="Proposed" />
                      </CardSubtitle>
                      <CardText>
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_proposed.buy.proposal.actors_detail"
                          defaultMessage="{counterparty} has proposed to buy from {actor}"
                          values={{
                            actor: <strong>{username(rule.seller.user)}</strong>,
                            counterparty: <strong>{username(rule.buyer.user)}</strong>,
                          }}
                        />
                        <br />
                        <FormattedMessage
                          id="trade_rule.trade_rule_active.trade_rule_proposed.buy.proposal.time_detail"
                          defaultMessage="Proposed by {actor} on {timestamp}"
                          values={{
                            actor: <strong>{username(rule.proposedBy)}</strong>,
                            timestamp: <Time
                              child={getStringAndNumericFormattedDate(intl, rule.proposedAt)}
                              unixTimestamp={rule.proposedAt}
                            />,
                          }}
                        />
                        <br />
                        {
                          buyPriceInformation({
                            mode: APIConfig().MODE,
                            minimum,
                            maximum,
                            detailsLink: <Link to={`/properties/${property.id}/trade-rules/${rule.id}`}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.buy.further_details" defaultMessage="further details" /></Link>,
                            units: <abbr title={formatMessage({ id: 'trade_rule.trade_rule_active.trade_rule_proposed.buy.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}><FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.buy.energy_cost.abbr.label" defaultMessage="c/kWh" /></abbr>,
                          })
                        }
                        <br />
                        <Link
                          to={`/properties/${property.id}/trade-rules/${rule.id}`}
                        >
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                    <CardFooter>{this.tradeRuleActionButton(rule)}</CardFooter>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Link
            to={`/properties/${property.id}/trade-rules?type=TRADE_RULE_TYPE_PROPOSED`}
          >
            <Button color="primary" className="me-2">
              <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.view_all_trade_rules.label" defaultMessage="View all trade rules" />
            </Button>
          </Link>
          <Link
            to={`/properties/${property.id}/trade-rules/propose/nominated`}
          >
            <Button color="primary" className="me-2">
              <FormattedMessage id="trade_rule.trade_rule_active.trade_rule_proposed.propose_new_trade.label" defaultMessage="Propose new trade" />

            </Button>
          </Link>
        </CardFooter>
      </Card>
    );
  }
}

TradeRuleActiveProposed.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleActiveProposed.defaultProps = {
  property: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleActiveProposed,
  {
    property: graphql`
      fragment TradeRuleActiveProposed_property on Property {
        id
        meters {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              proposedRules: rules(first: 500, state: TRADE_RULE_STATE_PROPOSED) {
                edges {
                  node {
                    id
                    priority
                    tradeType
                    state
                    buyer {
                      userId
                      user { id email givenName familyName }
                      communityId
                      residualId
                      tradePoint { id type }
                    }
                    seller {
                      userId
                      user { id email givenName familyName }
                      communityId
                      residualId
                      tradePoint { id type }
                    }
                    clauses {
                      edges {
                        node {
                          price
                        }
                      }
                    }
                    start
                    finish
                    proposedAt
                    proposedBy { id email givenName familyName }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
));
