import PropTypes from 'prop-types';
import React from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import styled from 'styled-components';

import Contact from './Contact';

const ContactContainer = styled.div.attrs({
  className: 'row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4',
})``;

const ContactColumn = styled.div.attrs({
  className: 'col',
})``;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - ContactSearch component
 */
function ContactSearch(props) {
  const { users, searchInputFn } = props;

  return (
    <div>
      <Form>
        <FormGroup>
          <Input
            type="search"
            name="search"
            id="search"
            placeholder="Search Contacts"
            onChange={searchInputFn} // TODO:
          />
        </FormGroup>
      </Form>
      <ContactContainer>
        {users.map((user) => (
          <ContactColumn key={user.id}>
            <Contact
              avatar={user.avatar}
              username={user.username}
              email={user.email}
              id={user.id}
              options={[
                { name: 'Add Contact', path: '/add' },
              ]}
            />
          </ContactColumn>
        ))}
      </ContactContainer>
    </div>
  );
}

ContactSearch.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      username: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  searchInputFn: PropTypes.func,
};

ContactSearch.defaultProps = {
  users: [],
  searchInputFn: null,
};

export default ContactSearch;
