import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

import { AvatarItem } from 'src/enosikit/components/Avatar';
import capitalize from 'src/util/capitalize';
import {
  TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CANCELLED, TRADE_RULE_STATE_CLOSED,
  TRADE_RULE_STATE_PROPOSED, TRADE_RULE_STATE_REJECTED,
  TRADE_TYPE_COMMUNITY, TRADE_TYPE_CONTRACTED,
  TRADE_TYPE_NOMINATED, TRADE_TYPE_RESIDUAL,

} from 'src/util/constants';
import { getLocale } from 'src/util/i18n/handler';
import { getDateAndTimeFormattedString } from 'src/util/i18n/helpers';

const Step = styled.li.attrs({
  className: 'd-flex',
})`
  list-style: none;
`;

const Icon = styled.div.attrs((props) => ({
  className: props.isCurrentStep ? 'text-primary' : 'text-secondary',
}))`
  font-size: 1.5rem;
  opacity: 75%;
`;

const Line = styled.div.attrs({
  className: 'h-100 border border-dark',
})`
  opacity: 25%;
`;

const Header = styled.div.attrs({
  className: 'd-flex justify-content-between mt-2',
})``;

const Body = styled.div.attrs({
  className: 'card card-body mb-3 mt-3',
})``;

const List = styled.ul.attrs({
  className: 'mt-0 mx-0 mb-n3 p-0',
})`
  list-style: none;
`;

const Actor = styled.span`
  vertical-align: middle;
`;

const getStepType = (state, intl) => {
  let stepType = '';
  switch (state) {
    case TRADE_RULE_STATE_PROPOSED:
      stepType = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_rule_state.proposed.label', defaultMessage: 'Proposed' });
      break;
    case TRADE_RULE_STATE_ACCEPTED:
      stepType = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_rule_state.accepted.label', defaultMessage: 'Accepted' });
      break;
    case TRADE_RULE_STATE_CANCELLED:
      stepType = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_rule_state.cancelled.label', defaultMessage: 'Cancelled' });
      break;
    case TRADE_RULE_STATE_REJECTED:
      stepType = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_rule_state.rejected.label', defaultMessage: 'Rejected' });
      break;
    case TRADE_RULE_STATE_CLOSED:
      stepType = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_rule_state.closed.label', defaultMessage: 'Closed' });
      break;
    default:
      stepType = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_rule_state.unspecified.label', defaultMessage: 'Unspecified' });
  }
  return stepType;
};

const getTradeType = (tradeType, intl) => {
  let type = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_type.unspecified.label', defaultMessage: 'Unspecified' }) || '';

  switch (tradeType) {
    case TRADE_TYPE_CONTRACTED:
      type = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_type.contracted.label', defaultMessage: 'contracted' });
      break;
    case TRADE_TYPE_NOMINATED:
      type = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_type.nominated.label', defaultMessage: 'peer-to-peer' });
      break;
    case TRADE_TYPE_COMMUNITY:
      type = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_type.community.label', defaultMessage: 'community' });
      break;
    case TRADE_TYPE_RESIDUAL:
      type = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_type.residual.label', defaultMessage: 'retailer default' });
      break;
    default:
      type = intl.formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_timeline_step.trade_type.unspecified.label', defaultMessage: 'Unspecified' });
  }
  return type;
};

/**
 * Description
 * @param {any} props
 * @returns {any} - TradeRuleTimelineStep component
 */
function TradeRuleTimelineStep(props) {
  const {
    ruleId, tradeType, state, timestamp, user, icon, isCurrentStep,
    hasNextStep, children, timezone,
  } = props;

  const intl = useIntl();

  const type = getTradeType(tradeType, intl)?.toUpperCase();

  const stepType = getStepType(state, intl)?.toLowerCase();

  const locale = getLocale();
  const ts = DateTime.fromSeconds(timestamp);

  let actor;
  if (user) {
    const { email, givenName, familyName } = user;
    let name = [givenName, familyName].filter(Boolean).join(' ');
    if (name === '') {
      name = email;
    }
    actor = (
      <AvatarItem
        primaryText={name}
        secondaryText={email}
        avatarImage={
          <FontAwesomeIcon icon={faUserCircle} size="3x" />
        }
        className="d-inline-flex"
      />
    );
  } else {
    actor = <FormattedMessage id="trade_rule.trade_rule_timeline.trade_rule_timeline_step.actor.system.label" defaultMessage="System" />;
  }

  return (
    <Step>
      <div className="d-flex flex-column align-items-center">
        <Icon isCurrentStep={isCurrentStep}>{icon}</Icon>
        {hasNextStep && <Line />}
      </div>
      <div className="ms-3 w-100">
        <Header>
          <div>
            <FormattedMessage
              id="trade_rule.trade_rule_timeline.trade_rule_timeline_step.header"
              defaultMessage="{tradeRuletype} trade rule {steptype}"
              values={{
                tradeRuletype: <strong>{type}</strong>,
                steptype: <strong>{stepType}</strong>,
              }}
            />
          </div>
          <div>
            <time id={`timestamp-${ruleId}-${stepType}`} dateTime={ts.toISO()}>
              {getDateAndTimeFormattedString(locale, timezone, ts)}
            </time>
            <UncontrolledTooltip target={`timestamp-${ruleId}-${stepType}`}>
              {ts.toISO()}
            </UncontrolledTooltip>
          </div>
        </Header>
        <Body>
          <List>
            <li className="mb-3">
              <FormattedMessage
                id="trade_rule.trade_rule_timeline.trade_rule_timeline_proposal.details"
                defaultMessage="{steptype} by {actor}"
                values={{
                  steptype: <strong>{capitalize(stepType)}</strong>,
                  actor: <Actor>{actor}</Actor>,
                }}
              />
            </li>
            {children}
          </List>
        </Body>
      </div>
    </Step>
  );
}

TradeRuleTimelineStep.propTypes = {
  timezone: PropTypes.string.isRequired,
  ruleId: PropTypes.string.isRequired,
  tradeType: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }).isRequired,
  icon: PropTypes.node.isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  hasNextStep: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

TradeRuleTimelineStep.defaultProps = {
  children: null,
};

export default TradeRuleTimelineStep;
