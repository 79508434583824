import { config } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle, faCircle, faStopCircle, faTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Alert, Button, Card, CardBody, CardFooter, CardText, CardTitle,
} from 'reactstrap';
import styled from 'styled-components';

// CSS for Fontawesome
config.autoAddCss = true;

const TradeIdBadge = styled.strong.attrs({
  className: 'badge badge-default',
})``;

const CloseTradeButton = styled(Button).attrs({
  className: 'btn btn-secondary',
})``;

/**
 * @deprecated component not being used
 */
class TradeRuleCard extends React.Component {
  // TODO: add available actions
  // actions = (tradeRuleType, tradeRuleState) => {};
  static actions() {
    return <CloseTradeButton>Close agreed trade</CloseTradeButton>;
  }

  static counterPartyText(tradeDirection, buyer, seller) {
    switch (tradeDirection) {
      case 'TRADE_DIRECTION_BUY':
        return (
          <span>
            Buying from:&nbsp;
            <strong>{buyer.id}</strong>
          </span>
        );
      case 'TRADE_DIRECTION_SELL':
        return (
          <span>
            Selling to:&nbsp;
            <strong>{seller.id}</strong>
          </span>
        );
      default:
        return (
          <Alert color="warning" className="mb-0">
            <strong>Error </strong>
            no trade direction listed for trade rule.
          </Alert>
        );
    }
  }

  static dollarsPerWhToCPerKWh(value) {
    return (Math.round(value * (100 * 1000 * 100000)) / 100000);
  }

  static title(tradeRule) {
    switch (tradeRule.state) {
      case 'TRADE_RULE_STATE_PROPOSED':
        return (
          <>
            <FontAwesomeIcon icon={faCircle} />
            &nbsp;Proposed
          </>
        );
      case 'TRADE_RULE_STATE_ACCEPTED':
        return (
          <>
            <FontAwesomeIcon icon={faCheckCircle} />
            &nbsp;Accepted
          </>
        );
      case 'TRADE_RULE_STATE_CLOSED':
        return (
          <>
            <FontAwesomeIcon icon={faStopCircle} />
            &nbsp;Closed
          </>
        );
      case 'TRADE_RULE_STATE_CANCELLED':
        return (
          <>
            <FontAwesomeIcon icon={faTimesCircle} />
            &nbsp;Cancelled
          </>
        );
      case 'TRADE_RULE_STATE_REJECTED':
        return (
          <>
            <FontAwesomeIcon icon={faTimesCircle} />
            &nbsp;Rejected
          </>
        );
      default:
        return (
          <>Undefined</>
        );
    }
  }

  render() {
    const { tradeRule, tradeDirection } = this.props;
    const { acceptedAt, buyer, seller } = tradeRule;
    const acceptedAtFormatted = DateTime.fromISO(acceptedAt).toLocal().toFormat("dd LLLL yyyy 'at' t", { locale: 'en' });

    return (
      <Card>
        <CardBody>
          <CardTitle tag="h5">
            {this.title(tradeRule)}
          </CardTitle>
          <CardText>
            {this.counterPartyText(tradeDirection, buyer, seller)}
            <br />
            Proposed by:&nbsp;
            <strong>{tradeRule.proposedBy}</strong>
            <br />
            Approved by&nbsp;
            <strong>{tradeRule.acceptedBy}</strong>
            &nbsp;on&nbsp;
            {acceptedAtFormatted}
            <br />
            Price:&nbsp;
            <span>
              <strong>{this.dollarsPerWhToCPerKWh(tradeRule.price)}</strong>
              &nbsp;c/kWh
            </span>
            <br />
            <TradeIdBadge>{tradeRule.id}</TradeIdBadge>
          </CardText>
        </CardBody>
        <CardFooter>
          {this.actions()}
        </CardFooter>
      </Card>
    );
  }
}

TradeRuleCard.propTypes = {
  tradeDirection: PropTypes.oneOf([
    'TRADE_DIRECTION_BUY',
    'TRADE_DIRECTION_SELL',
  ]).isRequired,
  tradeRule: PropTypes.shape({
    id: PropTypes.string,
    tradeType: PropTypes.string,
    buyer: PropTypes.shape({
      id: PropTypes.string,
      tradePoint: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.oneOf([
          'TRADE_POINT_TYPE_KNOWN',
          'TRADE_POINT_TYPE_COMMUNITY',
          'TRADE_POINT_TYPE_RESIDUAL',
        ]),
      }),
    }),
    seller: PropTypes.shape({
      id: PropTypes.string,
      tradePoint: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.oneOf([
          'TRADE_POINT_TYPE_KNOWN',
          'TRADE_POINT_TYPE_COMMUNITY',
          'TRADE_POINT_TYPE_RESIDUAL',
        ]),
      }),
    }),
    price: PropTypes.number,
    start: PropTypes.string,
    finish: PropTypes.string,
    state: PropTypes.oneOf([
      'TRADE_RULE_STATE_PROPOSED',
      'TRADE_RULE_STATE_ACCEPTED',
      'TRADE_RULE_STATE_CANCELLED',
      'TRADE_RULE_STATE_REJECTED',
      'TRADE_RULE_STATE_CLOSED',
    ]),
    proposedBy: PropTypes.string,
    proposedAt: PropTypes.string,
    acceptedBy: PropTypes.string,
    acceptedAt: PropTypes.string,
    sequenceId: PropTypes.number,
  }).isRequired,
};

export default TradeRuleCard;
