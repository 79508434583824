import Link from 'found/Link';
import React from 'react';

import UUID from 'src/components/UUID';
import {
  DATA_AGGREGATE_BY_METER, DATA_AGGREGATE_BY_PROPERTY,
  DATA_GROUP_BY_COUNTERPARTY, DATA_GROUP_BY_TRADE_TYPE,
} from 'src/util/constants';

/**
 * Renders the label for the meter data cards (i.e aggregated by meter) in the portfolio dashboard.
 * @param {object} obj contains id and title.
 * @param {string} obj.id - The property's id.
 * @param {string} obj.title - The property's title
 * @param {string} obj.start The start of the time range on the property dashboard.
 * @param {string} obj.finish The finish of the time range on the property dashboard.
 * @param {DATA_AGGREGATE_BY_PROPERTY | DATA_AGGREGATE_BY_METER} obj.aggregateBy
 * @param {DATA_GROUP_BY_COUNTERPARTY | DATA_GROUP_BY_TRADE_TYPE} obj.groupBy
 * @returns {React.ReactElement | null} - The rendered meter content property label.
 */
export const propertyLink = ({
  id, title, start, finish, aggregateBy, groupBy,
}) => {
  if (!id) {
    return null;
  }

  const label = title || <UUID uuid={id} />;
  const to = { pathname: `/properties/${id}`, query: {} };

  if (start) { to.query.start = start; }
  if (finish) { to.query.finish = finish; }
  if (aggregateBy) { to.query.aggregateBy = aggregateBy; }
  if (groupBy) { to.query.groupBy = groupBy; }

  return (
    <Link to={to}>{label}</Link>
  );
};

/**
 * Renders the label a property's trade rule.
 * @param {string} propertyId the property's id.
 * @param {string} tradeRuleId the id of the trade rule at the property.
 * @param {string} label a label to use for the link.
 * @returns {React.ReactElement | null} - The rendered meter content property label.
 */
export const propertyTradeRuleLink = (propertyId, tradeRuleId, label) => {
  if (!propertyId || !tradeRuleId) {
    return null;
  }

  return (
    <Link to={`/properties/${propertyId}/trade-rules/${tradeRuleId}`}>{label}</Link>
  );
};
