import { Link } from 'found';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';

import TradeRuleListTradePoints from './TradeRuleListTradePoints';

class TradeRuleList extends React.Component {
  renderPropertyControls = () => {
    const { property } = this.props;
    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.id}/trade-rules/active`} className="btn btn-darken">
            <FormattedMessage id="trade_rule.trade_rule_list.active_trade_rules.label" defaultMessage="Active trade rules" />
          </Link>
        </ButtonGroup>

        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.id}/settings`} className="btn btn-darken">
            <FormattedMessage id="trade_rule.trade_rule_list.property_settings.label" defaultMessage="Property Settings" />
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { property, intl } = this.props;
    const { address, id, title } = property;

    const addr = new Address(address);
    const pageTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_list.page.title', defaultMessage: 'Enosi - Property: {title} - Trade Rules' }, { title });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            {
              name: <FormattedMessage id="trade_rule.trade_rule_list.properties.breadcrumb" defaultMessage="Properties" />, path: '/properties',
            },
            { name: title, path: `/properties/${id}` },
            { name: <FormattedMessage id="trade_rule.trade_rule_list.trade_rules.breadcrumb" defaultMessage="Trade Rules" /> },
          ]}
        />
        <Heading
          alias={title}
          address={addr.simpleString()}
          context={<FormattedMessage id="trade_rule.trade_rule_list.heading.trade_rules.title" defaultMessage="Trade Rules" />}
          controls={this.renderPropertyControls()}
        />
        <TradeRuleListTradePoints property={property} date={DateTime.local()} />
      </>
    );
  }
}

TradeRuleList.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
};

TradeRuleList.defaultProps = {
  property: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleList,
  {
    property: graphql`
      fragment TradeRuleList_property on Property @argumentDefinitions(
        first: { type: "Int"}
        after: { type: "String" }
        last: { type: "Int"}
        before: { type: "String" }
      ) {
        id
        title
        address {
          line1
          line2
          city
          state
          postcode
          gps {
            latitude
            longitude
          }
        }
        ...TradeRuleListTradePoints_property @arguments(
          first: $first
          after: $after
          last: $last
          before: $before
        )
      }
    `,
  },
));
