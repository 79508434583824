import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Button, Card, CardBody, CardFooter, CardHeader,
  Form, FormFeedback, FormGroup, FormText, Input, Label,
} from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';

import FlashesStore from 'src/stores/FlashesStore';

class ProfileSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    let email = '';
    let emailValid = null;
    let familyName = '';
    let givenName = '';

    if (props && props.viewerUser) {
      email = props.viewerUser.email;
      familyName = props.viewerUser.familyName;
      givenName = props.viewerUser.givenName;

      if (email !== null) {
        emailValid = isEmail(email);
      }
    }

    this.state = {
      email,
      emailValid,
      familyName,
      givenName,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const {
      handleUpdate, processing, intl, handleUpdateNotRequired,
    } = this.props;

    if (processing) {
      const msgProcessing = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.state.invalid_still_processing', defaultMessage: 'We are still processing your request...' });
      FlashesStore.flash(FlashesStore.INFO, msgProcessing);
      return;
    }

    if (!this.isValid()) {
      const msgInvalidData = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.state.invalid_data', defaultMessage: 'Form data not valid. Please see below.' });
      FlashesStore.flash(FlashesStore.ERROR, msgInvalidData);
      return;
    }

    const { email, familyName, givenName } = this.state;

    if (!this.formHasChanged()) {
      handleUpdateNotRequired(intl);
      return;
    }

    const input = { email, familyName, givenName };

    handleUpdate(input);
  };

  handleEmailChange = (event) => {
    const { value: email } = event.target;
    const emailValid = isEmail(email);

    this.setState({ email, emailValid });
  };

  handleFamilyNameChange = (event) => {
    const { value: familyName } = event.target;
    this.setState({ familyName });
  };

  handleGivenNameChange = (event) => {
    const { value: givenName } = event.target;
    this.setState({ givenName });
  };

  isValid = () => {
    const { emailValid } = this.state;

    return emailValid;
  };

  /**
   * checks whether the form has changed.
   * @returns {boolean}
   */

  formHasChanged = () => {
    const { viewerUser } = this.props;
    const { email: prevEmail, familyName: prevFamilyName, givenName: prevGivenName } = viewerUser;
    const { email, familyName, givenName } = this.state;

    if (email === prevEmail && familyName === prevFamilyName && givenName === prevGivenName) {
      return false;
    }
    return true;
  };

  render() {
    const { processing, intl } = this.props;
    const {
      email, emailValid, familyName, givenName,
    } = this.state;

    const cardHeader = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.header', defaultMessage: 'Profile' });
    const cardBodyTitle = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.title', defaultMessage: 'Update your details.' });

    const emailLabel = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.email.label', defaultMessage: 'Email' });
    const emailFeedbackInvalid = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.email.form_feedback.invalid', defaultMessage: 'Invalid email address' });
    const emailHelpText = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.email.help_text', defaultMessage: 'Changing your email will require confirmation. An email will be sent to your new address to allow you to confirm it. Until then you will still log in using your existing email.' });
    const emailPlaceholder = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.email.placeholder', defaultMessage: 'Your email' });

    const givenNameLabel = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.given_name.label', defaultMessage: 'Given name' });
    const givenNameFeedbackOptional = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.given_name.feedback_text', defaultMessage: 'Your given name is optional' });
    const givenNameHelpText = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.given_name.help_text', defaultMessage: 'Your given name is optional' });
    const givenNamePlaceholder = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.given_name.placeholder', defaultMessage: 'Your given name' });

    const familyNameLabel = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.family_name.label', defaultMessage: 'Family name' });
    const familyNameFeedbackOptional = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.family_name.feedback_text', defaultMessage: 'Your family name is optional' });
    const familyNameHelpText = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.family_name.help_text', defaultMessage: 'Your family name is optional' });
    const familyNamePlaceholder = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.family_name.placeholder', defaultMessage: 'Your family name' });

    const submitLabel = intl.formatMessage({ id: 'profile.profile_settings.profile_settings_form.submit.label', defaultMessage: 'Update' });

    return (
      <Form onSubmit={this.handleSubmit} disabled={processing}>
        <Card className="mt-4 mb-4">
          <CardHeader tag="h5">{cardHeader}</CardHeader>
          <CardBody>
            <p>{cardBodyTitle}</p>
            <FormGroup>
              <Label for="userEmail">{emailLabel}</Label>
              <Input name="userEmail" id="userEmail" placeholder={emailPlaceholder} value={email} onChange={(ev) => (this.handleEmailChange(ev))} disabled={processing} valid={emailValid} invalid={emailValid !== null && !emailValid} />
              <FormFeedback>{emailFeedbackInvalid}</FormFeedback>
              <FormText color="muted">{emailHelpText}</FormText>
            </FormGroup>

            <FormGroup>
              <Label for="userGivenName">{givenNameLabel}</Label>
              <Input name="userGivenName" id="userGivenName" placeholder={givenNamePlaceholder} value={givenName} onChange={(ev) => (this.handleGivenNameChange(ev))} disabled={processing} />
              <FormFeedback>{givenNameFeedbackOptional}</FormFeedback>
              <FormText color="muted">{givenNameHelpText}</FormText>
            </FormGroup>

            <FormGroup>
              <Label for="userFamilyName">{familyNameLabel}</Label>
              <Input name="userFamilyName" id="userFamilyName" placeholder={familyNamePlaceholder} value={familyName} onChange={(ev) => (this.handleFamilyNameChange(ev))} disabled={processing} />
              <FormFeedback>{familyNameFeedbackOptional}</FormFeedback>
              <FormText color="muted">{familyNameHelpText}</FormText>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button disabled={processing}>{submitLabel}</Button>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

ProfileSettingsForm.propTypes = {
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleUpdate: PropTypes.func,
  handleUpdateNotRequired: PropTypes.func,
  processing: PropTypes.bool,
  viewerUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

ProfileSettingsForm.defaultProps = {
  handleUpdate: null,
  processing: false,
  viewerUser: null,
  handleUpdateNotRequired: null,
};

export default createFragmentContainer(
  injectIntl(ProfileSettingsForm),
  {
    viewerUser: graphql`
      fragment ProfileSettingsForm_viewerUser on User {
        id
        email
        familyName
        givenName
      }
    `,
  },
);
