import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, useRefetchableFragment } from 'react-relay';

import DashboardControl from 'src/components/Dashboard/DashboardControl';
import { WARNING_TIMEOUT, showTradeRuleMsg } from 'src/components/Dashboard/DashboardControl/helpers/common';
import Loading from 'src/components/Loading';
import subHeading from 'src/components/Portfolio/helpers/common';
import { APIConfig } from 'src/config';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import FlashesStore from 'src/stores/FlashesStore';
import {
  PORTFOLIO_SHOW_DEFAULT_VIEW, SOURCE, SOURCE_TRADES, TIME_ZONE_SYSTEM,
  UNIT, UNIT_CURRENCY, UNIT_ENERGY, VIEW_SELECTOR,
} from 'src/util/constants';
import BrowserProtocol from 'src/util/history';
import { getDefaultLocale } from 'src/util/i18n/handler';
import { dateRangeToTimeRange, getDashboardTimeScope } from 'src/util/time';

import PortfolioShowTradeEnergy from './PortfolioShowTradeEnergy';
import { buildMainData } from './data/mainDataBuilder';
import chartViewSelector from './helpers/portfolioShow';

const PortfolioShowFragment = graphql`
fragment PortfolioShow on Query
@refetchable(queryName: "PortfolioShowRefetchQuery")
@argumentDefinitions(
  id: {type: "ID!"},
  start: { type: "Timestamp!" },
  finish: { type: "Timestamp!" },
  timeZone: { type: "String!" }
  historianAggregation: { type: "String!" }
  tradeAggregation: { type: "String!"  }
) {
  portfolio(id:$id) {
  id
  externalIdentifier
  active { start finish }
  title
  userMembers(first: 500) {
    edges {
      node {
        id
        externalIdentifier
        active { start finish }
        role
        user {
          id
          externalIdentifier
          email
          givenName
          familyName
          active { start finish }
        }
      }
    }
  }
  propertyMembers(first: 500) {
    edges {
      node {
        id
        externalIdentifier
        active { start finish }
        property {
          id
        }
      }
    }
  }
  meters(first:500) {
    edges {
      node {
        id
        identifier
        externalIdentifier
        active { start finish }
        title
        tradePointId
        primaryBillingPoint {
          identifier
        }
        property {
          externalIdentifier
          id
          title
          publicHolidayRegion
        }
        rules(first:999999999, start: $start, finish: $finish) {
          edges {
            node {
              id
              tradeType
              buyer {
                userId
                user {
                  id
                  email
                  givenName
                  familyName
                }
                tradePoint {
                  id
                  type
                  meter {
                    id
                    identifier
                    title
                    property {
                      id
                      title
                    }
                  }
                }
              }
              seller {
                userId
                user {
                  id
                  email
                  givenName
                  familyName
                }
                tradePoint {
                  id
                  type
                  meter {
                    id
                    identifier
                    title
                    property {
                      id
                      title
                    }
                  }
                }
              }
              state
              clauses {
                edges {
                  node {
                    price
                    ignorePublicHolidays
                    monthsOfYear
                    daysOfWeek
                    timesOfDay {
                      start { hours minutes }
                      finish { hours minutes }
                    }
                  }
                }
              }
              proposedAt
              proposedBy {
                id
                email
                givenName
                familyName
              }
              acceptedAt
              acceptedBy {
                id
                email
                givenName
                familyName
              }
              start
              finish
            }
          }
        }
        dataConsumed: calculatedData(start: $start, finish: $finish, aggregation: $historianAggregation, timeZone: $timeZone, metric: "elec_energy_consumed") {
          aggregation
          metric {
            identifier
          }
          timeZone
          timeRange {
            start
            finish
          }
          data {
            timestamp
            value
            flags {
              identifier
              description
            }
          }
        }
        dataGenerated: calculatedData(start: $start, finish: $finish, aggregation: $historianAggregation, timeZone: $timeZone, metric: "elec_energy_generated") {
          aggregation
          metric {
            identifier
          }
          timeZone
          timeRange {
            start
            finish
          }
          data {
            timestamp
            value
            flags {
              identifier
              description
            }
          }
        }
        tradeSetSummary(start: $start, finish: $finish, timeZone: $timeZone, aggregation: $tradeAggregation, groups: [SUMMARY_GROUP_TRADE_POINT, SUMMARY_GROUP_TRADE_RULE, SUMMARY_GROUP_DIRECTION, SUMMARY_GROUP_TRADE_TYPE]) {
          key {
            tradePointId
            ruleId
            direction
            type
          }
          data {
            range { start finish }
            buyerTradePointIds
            sellerTradePointIds
            directions
            types
            value
            volume
            averagePrice
            tradeCount
            intervalCount
          }
        }
      }
    }
  }
 }
}
`;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - PortfolioShow component
 */
function PortfolioShow(props) {
  if (!props) {
    return <Loading />;
  }
  const [data, refetch] = useRefetchableFragment(PortfolioShowFragment, props);
  const { portfolio } = data || {};
  const { __fragmentOwner } = props || {};
  const { variables } = __fragmentOwner || {};
  const { start, finish, timeZone } = variables || {};
  const portfolioTimezone = timeZone || TIME_ZONE_SYSTEM;
  const { s, f, agg } = getDashboardTimeScope(start, finish);

  // These linting comments will be removed once all the features are implemented
  // eslint-disable-next-line no-unused-vars
  const [historianAggregation, setHistorianAggregation] = useState(agg);
  // eslint-disable-next-line no-unused-vars
  const [tradeAggregation, setTradeAggregation] = useState(agg);
  const [timespan, setTimespan] = useState({
    start: s,
    finish: f,
  });

  const [unit, setUnit] = useState(UNIT_ENERGY);
  const [source, setSource] = useState(SOURCE_TRADES);
  const [tradeBtnClicks, setTradeBtnClicks] = useState(0);
  const [viewSelectorOpen, setViewSelectorOpen] = useState(false);
  const [downloadSelectorOpen, setDownloadSelectorOpen] = useState(false);
  const [chartView, setChartView] = useState(PORTFOLIO_SHOW_DEFAULT_VIEW);
  const firstTimeRender = useRef(true);

  const mainData = buildMainData(portfolio, portfolioTimezone);
  const { properties, users } = mainData || {};
  const propertiesCount = Object.keys(properties)?.length || 0;
  const usersCount = Object.keys(users)?.length || 0;

  const doRefetch = () => {
    let tz = APIConfig().DEFAULT_TIMEZONE;
    if (timeZone && timeZone !== undefined) {
      tz = timeZone;
    }

    const { start: startTime, finish: finishTime } = dateRangeToTimeRange(timespan, tz);
    const timespanDuration = finishTime.diff(startTime);

    let aggregate = 'PT30M';
    if (timespanDuration.as('days') > 7) {
      aggregate = 'P1D';
    }

    refetch(
      {
        id: portfolio.id,
        start: startTime.toSeconds(),
        finish: finishTime.toSeconds(),
        timeZone: tz,
        historianAggregation: aggregate,
        tradeAggregation: aggregate,
      },

      {
        onComplete: (error) => {
          if (error) {
            FlashesStore.flash(FlashesStore.ERROR, error);
          } else {
            const { match } = props;
            const { location } = match;
            const { pathname } = location;
            setHistorianAggregation(aggregate);
            setTradeAggregation(aggregate);

            const state = {
              start: startTime.toISODate({ zone: portfolioTimezone }),
              finish: finishTime.minus({ days: 1 }).toISODate({ zone: portfolioTimezone }),
            };

            BrowserProtocol.navigate({
              action: 'REPLACE',
              pathname,
              search: `?${Object.keys(state).map((k) => [k, encodeURIComponent(state[k])].join('=')).join('&')}`,
              hash: '',
              state,
            });
          }
        },

      },
    );
  };

  useEffect(() => {
    if (!firstTimeRender.current) {
      doRefetch();
    }
  }, [timespan]);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);
  const toggle = (dropdownType) => {
    if (dropdownType === VIEW_SELECTOR) {
      setViewSelectorOpen(!viewSelectorOpen);
    } else {
      setDownloadSelectorOpen(!downloadSelectorOpen);
    }
  };

  const { title } = portfolio || {};
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'portfolio.portfolio_show.page.title', defaultMessage: 'Enosi - Portfolio: {portfolioTitle}' }, { portfolioTitle: title });
  const breadcrumbTitle = intl.formatMessage({ id: 'portfolio.portfolio_show.breadcrumbs.portfolio.label', defaultMessage: 'Portfolios' });
  const overviewTitle = intl.formatMessage({ id: 'portfolio.portfolio_show.heading.overview.title', defaultMessage: 'Overview' });

  const controlSetStateFunc = (opts) => {
    const { group, value } = opts;

    let chartSource = source;
    let chartUnit = unit;
    let btnClicks = tradeBtnClicks;

    FlashesStore.reset();
    if (value === chartSource && chartSource === SOURCE_TRADES) {
      if (btnClicks === 0) {
        clearTimeout();
        btnClicks += 1;
      } else {
        showTradeRuleMsg(intl);
      }
      setTimeout(() => {
        setTradeBtnClicks(0);
      }, WARNING_TIMEOUT);
    }
    if (group === UNIT) {
      chartUnit = value;
    }
    if (group === SOURCE) {
      chartSource = value;
      if (chartUnit === UNIT_CURRENCY) {
        chartUnit = UNIT_ENERGY;
      }
    }
    setSource(chartSource);
    setUnit(chartUnit);
    setTradeBtnClicks(btnClicks);
  };

  const handleTimespanUpdate = (updatedStart, updatedFinish) => {
    setTimespan({ start: updatedStart, finish: updatedFinish });
  };

  const renderPortfolioControls = (portfolioData) => {
    const { start: startTime, finish: finishTime } = timespan;
    const locale = getDefaultLocale();
    const updatedStartTime = startTime.setLocale(locale).setZone(portfolioTimezone);
    const updatedFinishTime = finishTime.setLocale(locale).setZone(portfolioTimezone);

    return (
      <DashboardControl
        chartView={chartView}
        chartViewSelector={chartViewSelector}
        controlSetStateFunc={(opts) => controlSetStateFunc(opts)}
        downloadSelectorOpen={downloadSelectorOpen}
        mainData={portfolioData}
        timespan={{ start: updatedStartTime, finish: updatedFinishTime }}
        setChartViewFunc={setChartView}
        source={source}
        timespanUpdateFunc={handleTimespanUpdate}
        toggleFunc={toggle}
        viewSelectorOpen={viewSelectorOpen}
        unit={unit}
      />
    );
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
      <Breadcrumbs breadcrumbs={[{ name: breadcrumbTitle, path: '/portfolios' }, { name: title }]} />
      <Heading
        alias={title}
        address={subHeading(propertiesCount, usersCount)}
        context={overviewTitle}
        controls={renderPortfolioControls(mainData)}
      />
      <PortfolioShowTradeEnergy
        chartView={chartView}
        controlSetStateFunc={controlSetStateFunc}
        handleTimespanUpdate={handleTimespanUpdate}
        historianAggregation={historianAggregation}
        mainData={mainData}
        timespan={timespan}
        tradeAggregation={tradeAggregation}
        unit={unit}
        source={source}
      />
    </>
  );
}

export default PortfolioShow;

PortfolioShow.propTypes = {
  match: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }),
};

PortfolioShow.defaultProps = {
  relay: undefined,
};
