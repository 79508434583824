import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';

import BadgeActive from 'src/components/Badge/BadgeActive';
import BadgeTradePointType from 'src/components/Badge/BadgeTradePointType';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import username from 'src/util/decorators/username';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminTradeRuleListTrader component
 */
function AdminTradeRuleListTrader(props) {
  if (!props) {
    return <Loading />;
  }

  const { trader } = props;
  const { tradePoint, community, user } = trader;
  const { id: tradePointId } = tradePoint;

  const tradePointBadge = <BadgeTradePointType type={tradePoint.type} />;

  if (user) {
    const { id, email, active } = user;

    return (
      <>
        <UUID uuid={tradePointId} />
        <br />
        {tradePointBadge}
        <br />
        <Link to={`/admin/users/${id}`}>
          {username(user)}
        </Link>
        <br />
        {email}
        <br />
        <BadgeActive start={active.start} finish={active.finish} />
      </>
    );
  }

  if (community) {
    const { id, title, active } = community;

    return (
      <>
        <UUID uuid={tradePointId} />
        <br />
        {tradePointBadge}
        <br />
        <Link to={`/admin/communities/${id}`}>
          {title}
        </Link>
        <br />
        <BadgeActive start={active.start} finish={active.finish} />
      </>
    );
  }

  return (
    <>
      <UUID uuid={tradePointId} />
      <br />
      {tradePointBadge}
    </>
  );
}

AdminTradeRuleListTrader.propTypes = {
  trader: PropTypes.shape({
    tradePoint: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    }),
    community: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
    user: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
  }),
};

AdminTradeRuleListTrader.defaultProps = {
  trader: null,
};

export default AdminTradeRuleListTrader;
