import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation RejectNominatedTradeMutation($id: ID!) {
    rejectNominatedTrade(id: $id) {
      id
    }
  }
`;

const RejectNominatedTradeMutation = (id, onCompleted, onError, formatMessage) => {
  const variables = { id };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response) => { onCompleted(response, formatMessage); },
      onError,
      updater: (store) => {
        // Get the current user record from the store
        const ruleProxy = store.get(id);
        ruleProxy.setValue('TRADE_RULE_STATE_REJECTED', 'state');
      },
    },
  );
};

export default RejectNominatedTradeMutation;
