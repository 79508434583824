import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
// import Timestamp from 'src/components/Time/Timestamp';
import UUID from 'src/components/UUID';

/**
 * Description
 * @param {object} props
 * @returns {any} - AdminUserShowPropertyUser component.
 */
function AdminUserShowPropertyUser(props) {
  if (!props) {
    return <tr><td colSpan={7}><Loading /></td></tr>;
  }

  const { propertyUser } = props;
  const {
    id, externalIdentifier, active, property,
  } = propertyUser;
  const { start, finish } = active;
  const {
    id: propertyId, title: propertyTitle, externalIdentifier: propertyExternalIdentifier,
    active: propertyActive,
  } = property;

  return (
    <tr key={`property-user-${id}`}>
      <th scope="row" className="text-right">
        <Link to={`/admin/property-users/${id}`}>
          <UUID uuid={id} />
        </Link>
      </th>
      <td>
        <BadgeActive start={start} finish={finish} />
        <br />
        {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
        -
        {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
      </td>
      <td>{externalIdentifier}</td>
      <td colSpan={2}>
        <Link to={`/admin/properties/${propertyId}`}>
          <strong>{propertyTitle}</strong>
          <br />
          <UUID uuid={propertyId} />
        </Link>
      </td>
      <td>
        <BadgeActive start={propertyActive.start} finish={propertyActive.finish} />
        <br />
        {propertyActive.start
          && DateTime.fromSeconds(propertyActive.start).toLocaleString(DateTime.DATETIME_FULL)}
        -
        {propertyActive.finish
          && DateTime.fromSeconds(propertyActive.finish).toLocaleString(DateTime.DATETIME_FULL)}
      </td>
      <td>{propertyExternalIdentifier}</td>
    </tr>
  );
}

AdminUserShowPropertyUser.propTypes = {
  propertyUser: PropTypes.shape({
    id: PropTypes.string,
    externalIdentifier: PropTypes.string,
    active: PropTypes.shape({
      start: PropTypes.number,
      finish: PropTypes.number,
    }),
    property: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      externalIdentifier: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
  }),
};

AdminUserShowPropertyUser.defaultProps = {
  propertyUser: null,
};

export default AdminUserShowPropertyUser;
