import { format } from 'd3-format';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Card, CardBody, CardFooter, Popover, PopoverBody, Progress,
} from 'reactstrap';
import styled from 'styled-components';

import { APIConfig } from 'src/config';
import { getTradeRuleContent, getUntradedContent } from 'src/helpers/tradeHelpers';
import {
  COST_BENEFIT_STATE_ICONS, EXPORTS, IMPORTS, NA_SYMBOL, PLATFORM_MODE_REBATE,
  RESIDUAL, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, UNTRADED_ENERGY_KEY,
} from 'src/util/constants';
import { costBenefitLabel, netValueDiffState } from 'src/util/counterfactual';
import { i18nDecimalFormat } from 'src/util/i18n/handler';
import isNumber from 'src/util/math';

const TradeType = styled.div.attrs({
  className: 'mt-2 py-1',
})`
  text-transform: uppercase;
  color: $primary;
`;

const TradePrimaryText = styled.div.attrs({
  className: 'py-1',
})`
  line-height: 1rem;
  font-size: 1.5rem;
`;

const TradeSecondaryText = styled.div.attrs({
  className: 'py-1',
})``;

const CostBenefitText = styled.span.attrs({
  className: 'ms-2',
})`
font-size: 0.8rem;
`;

const TradeProgress = styled(Progress).attrs({
  className: 'my-2 rounded-pill',
})`
  height: 1.5rem;
`;

const TradeProgressBar = styled(Progress).attrs({
  className: 'rounded-pill',
})``;

const TradeProgressLabel = styled.span`
  color: #fff;
  display: block;
  line-height: 200%;
  cursor: help;
`;

const UntradedProgressLabel = styled.span`
  color: #000000;
  display: block;
  line-height: 200%;
  cursor: help;
`;

/**
 * Trade summary component, energy import and energy export cards
 * @param {object} props
 * @returns {React.ReactElement} - trade summary component
 */
function TradeSummary(props) {
  const {
    category, data, hasCounterfactual, title: propertyTitle,
  } = props;
  const intl = useIntl();
  const [popoverState, setPopoverState] = useState('');

  const resetToggle = () => {
    setPopoverState('');
  };

  const toggle = (ruleId) => {
    setPopoverState(ruleId);
  };

  let title;
  let description;
  let progressColor;
  let direction;

  if (category === EXPORTS) {
    title = intl.formatMessage({ id: 'trade_summary.energy_exports.label', defaultMessage: 'Energy Exports' });
    description = intl.formatMessage({ id: 'trade_summary.exports.help_text', defaultMessage: 'A summary of where the energy you exported to the grid has been sold to.' });
    progressColor = 'primary';
    direction = TRADE_DIRECTION_SELL;
  } else {
    title = intl.formatMessage({ id: 'trade_summary.energy_imports.label', defaultMessage: 'Energy Imports' });
    description = intl.formatMessage({ id: 'trade_summary.imports.help_text', defaultMessage: 'A summary of where the energy you imported from the grid has been bought from.' });
    progressColor = 'secondary';
    direction = TRADE_DIRECTION_BUY;
  }

  let totalVolume = 0;
  Object.keys(data).forEach((type) => {
    totalVolume += data[type].reduce((sum, x) => sum + x.volume, 0);
  });

  const content = ['contracted', 'nominated', 'community', 'residual', 'untraded'].map((type) => {
    if (!data[type] || data[type].length === 0) {
      return null;
    }

    const untradedColor = category === 'exports' ? 'untradedSell' : 'untradedBuy';

    const typeLabels = {
      contracted: intl.formatMessage({ id: 'trade_summary.trade_type.contracted.label', defaultMessage: 'Contracted Trades' }),
      nominated: intl.formatMessage({ id: 'trade_summary.trade_type.nominated.label', defaultMessage: 'Peer-to-peer Trades' }),
      community: intl.formatMessage({ id: 'trade_summary.trade_type.community.label', defaultMessage: 'Community Trades' }),
      residual: intl.formatMessage({ id: 'trade_summary.trade_type.residual.label', defaultMessage: 'Retailer Default Trades' }),
      untraded: intl.formatMessage({ id: 'trade_summary.trade_type.untraded.label', defaultMessage: 'Untraded Energy' }),
    };
    const name = typeLabels[type];
    const typeValue = data[type].reduce((sum, x) => sum + x.value, 0);
    const typeVolume = data[type].reduce((sum, x) => sum + x.volume, 0);
    const getCounterfactual = (value) => (isNumber(value) ? value : 0);
    const counterfactual = data[type].reduce((
      sum,
      x,
    ) => sum + getCounterfactual(x.counterfactual), 0);

    const netValueDiff = typeValue - counterfactual;
    const absoluteValueDiff = Math.abs(netValueDiff);
    const diffState = netValueDiffState(netValueDiff, direction);
    const finalDiffState = diffState ? Object.keys(diffState).filter((k) => diffState[k])[0] : null;
    const progress = (
      <TradeProgress multi>
        {data[type].sort((a, b) => b.volume - a.volume).map((set) => {
          const { rule, volume } = set;
          const ruleId = rule?.id || type;
          const percentage = volume / totalVolume;
          const popoverTarget = `trade-rule-${ruleId}-${direction}`;
          return (
            <React.Fragment key={ruleId}>
              <TradeProgressBar
                bar
                value={100 * percentage}
                color={type === UNTRADED_ENERGY_KEY
                  ? untradedColor : progressColor}
              >
                {
                  type === UNTRADED_ENERGY_KEY
                    ? (
                      <UntradedProgressLabel
                        onMouseEnter={() => toggle(ruleId)}
                        onMouseOut={() => resetToggle()}
                        id={popoverTarget}
                      >
                        {`${format('.0%')(percentage)}`}
                      </UntradedProgressLabel>
                    ) : (
                      <TradeProgressLabel
                        onMouseEnter={() => toggle(ruleId)}
                        onMouseOut={() => resetToggle()}
                        id={popoverTarget}
                      >
                        {`${format('.0%')(percentage)}`}
                      </TradeProgressLabel>
                    )

                }
              </TradeProgressBar>
              <Popover placement="bottom" isOpen={popoverState === ruleId} target={popoverTarget} toggle={toggle}>
                <PopoverBody>
                  {type === UNTRADED_ENERGY_KEY
                    ? getUntradedContent(intl, propertyTitle)
                    : getTradeRuleContent(direction, rule, intl)}
                </PopoverBody>
              </Popover>
            </React.Fragment>
          );
        })}
      </TradeProgress>
    );

    let details;
    const isValueNaN = Number.isNaN(typeValue);
    if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
      details = (
        <>
          <TradePrimaryText>
            {/* TO DO: Implement d3-format locale support (PT-1124) */}
            <FormattedMessage
              id="trade_summary.platform_mode_rebate.trade_volume"
              defaultMessage="{typeVolume}Wh"
              values={{ typeVolume: format('.4s')(typeVolume) }}
            />
          </TradePrimaryText>
          {type !== RESIDUAL && (
            <TradeSecondaryText data-testid="tradeSummaryModeRebate">
              <FormattedMessage id="trade_summary.platform_mode_rebate.secondary.text" defaultMessage="Discount amount:" />
              <br />
              {/* TO DO: Implement d3-format locale support (PT-1124) */}
              {!isValueNaN ? (
                <FormattedMessage
                  id="common.currency.symbol"
                  defaultMessage="{n, number, ::currency/AUD}"
                  values={{ n: format('.2f')(typeValue) }}
                />
              ) : '-'}
            </TradeSecondaryText>
          )}
        </>
      );
    } else {
      const showValue = [UNTRADED_ENERGY_KEY].indexOf(type) === -1 || hasCounterfactual;
      const showCounterfactual = [RESIDUAL, UNTRADED_ENERGY_KEY].indexOf(type) === -1
        && hasCounterfactual;
      details = (
        <>
          <TradePrimaryText data-testid="tradeSummaryModeTrade">
            {/* TO DO: Implement d3-format locale support (PT-1124) */}
            {!isValueNaN ? (
              <>
                {showValue ? (
                  <FormattedMessage
                    id="common.currency.symbol"
                    defaultMessage="{n, number, ::currency/AUD}"
                    values={{ n: format('.2f')(typeValue) }}
                  />
                ) : NA_SYMBOL}
                {showCounterfactual && (
                  <CostBenefitText>
                    <span className="me-1">{COST_BENEFIT_STATE_ICONS[finalDiffState]}</span>
                    <FormattedMessage
                      id="trade_summary.platform_mode_trade.counterfactual.details"
                      defaultMessage=" {costBenefitAbbr}"
                      values={{ costBenefitAbbr: <abbr title={costBenefitLabel(intl, absoluteValueDiff, finalDiffState)}>{format('.2f')(absoluteValueDiff)}</abbr> }}
                    />
                  </CostBenefitText>
                )}
              </>
            ) : NA_SYMBOL}
          </TradePrimaryText>
          <TradeSecondaryText data-testid="tradeSummaryModeTradeVolume">
            <FormattedMessage
              id="trade_summary.platform_mode_trade.trade_volume"
              defaultMessage="{typeVolume}Wh"
              values={{ typeVolume: i18nDecimalFormat(format('.4s')(typeVolume)) }}
            />
          </TradeSecondaryText>
        </>
      );
    }

    return (
      <div key={type}>
        {progress}
        <TradeType data-testid="tradeSummaryTradeType">{name}</TradeType>
        {details}
      </div>
    );
  });

  return (
    <div className="col mb-4 mb-sm-0">
      <Card className={`h-100 trade-summary-${category}`}>
        <CardBody>
          <h2>{title}</h2>
          <div>{content}</div>
        </CardBody>
        <CardFooter>
          {description}
        </CardFooter>
      </Card>
    </div>
  );
}

TradeSummary.propTypes = {
  category: PropTypes.oneOf([IMPORTS, EXPORTS]).isRequired,
  data: PropTypes.shape({
    nominated: PropTypes.arrayOf(PropTypes.shape({
      tradeRuleId: PropTypes.string,
      value: PropTypes.number,
      volume: PropTypes.number,
    })),
    contracted: PropTypes.arrayOf(PropTypes.shape({
      tradeRuleId: PropTypes.string,
      value: PropTypes.number,
      volume: PropTypes.number,
    })),
    community: PropTypes.arrayOf(PropTypes.shape({
      tradeRuleId: PropTypes.string,
      value: PropTypes.number,
      volume: PropTypes.number,
    })),
    residual: PropTypes.arrayOf(PropTypes.shape({
      tradeRuleId: PropTypes.string,
      value: PropTypes.number,
      volume: PropTypes.number,
    })),
  }).isRequired,
  hasCounterfactual: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default TradeSummary;
