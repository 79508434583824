import Big from 'big.js';

import calculateCarbonData from 'src/lib/carbondata/calculator';
import {
  BUY,
  BUYER,
  DIRECTIONS,
  METER,
  SELLER,
  TIME_ZONE_SYSTEM,
  TRADE,
  TRADE_DIRECTIONS_LABEL,
  TRADE_TYPE_RESIDUAL,
} from 'src/util/constants';
import { getCounterFactualValue } from 'src/util/counterfactual';
import {
  extractData, filterRulesByTradePointId,
  getMemberNodes, getTimeStamp, hasCounterfactual,
} from 'src/util/mainDataBuilder';
import isNumber from 'src/util/math';

/**
 * Prepares a map of property members
 * @param {object} propertyMembers
 * @returns {object} - map of property members.
 */
export const getPropertyMembers = (propertyMembers) => {
  if (!propertyMembers) {
    return {};
  }
  const memberNodes = getMemberNodes(propertyMembers) || [];
  const members = {};
  memberNodes?.forEach((member) => {
    const { id } = member || {};
    members[id] = { ...member, propertyId: member.property?.id };
    // eslint-disable-next-line no-unused-vars
    const { property, ...remainingAttrs } = members[id] || {};
    members[id] = remainingAttrs;
  });
  return members;
};

/**
 * Prepares a map of portfolio user members
 * @param {object} userMembers
 * @returns {object} - map of portfolio user members.
 */
export const getUserMembers = (userMembers) => {
  if (!userMembers) {
    return {};
  }
  const memberNodes = userMembers.edges?.map((item) => (
    { ...item.node, userId: item.node.user?.id }));

  const members = {};
  const users = {};
  memberNodes?.forEach((member) => {
    const { id } = member || {};
    // eslint-disable-next-line no-unused-vars
    const { user, ...remainingAttrs } = member;
    members[id] = remainingAttrs;
    users[user?.id] = user;
  });
  return { members, users };
};

/**
 * Build main data that is to be used in portfolio dashboard
 * @param {object} portfolio The portfolio of properties.
 * @param {string} timezone The timezone of the portfolio.
 * @returns {object} - main data
 */
export const buildMainData = (portfolio, timezone) => {
  const {
    active, externalIdentifier, id, meters: allMeters, title,
    propertyMembers: propertyUsers, userMembers: portfolioUsers,
  } = portfolio || {};

  // get all meter nodes
  const meterNodes = getMemberNodes(allMeters) || [];
  const extractList = [];

  // data extraction
  if (meterNodes.length > 0) {
    meterNodes.forEach((node) => {
      const dataExtract = extractData(node);
      extractList.push(dataExtract);
    });
  }
  const userMembers = getUserMembers(portfolioUsers) || {};
  const { members, users } = userMembers;
  const propertyMembers = getPropertyMembers(propertyUsers);

  const mainData = {
    portfolio: {
      active,
      externalIdentifier,
      id,
      propertyMembers,
      title,
      userMembers: members,
    },
    buy: { rules: {}, data: {} },
    sell: { rules: {}, data: {} },
    meters: {},
    properties: {},
    users,
  };
  let buyTimestamps = [];
  let sellTimestamps = [];

  // form rules and extract timestamp list
  if (extractList.length > 0) {
    extractList.forEach((data) => {
      const {
        rules, tradePointId, buy, sell, meters,
      } = data;
      // eslint-disable-next-line no-unused-vars
      const { property, ...meterData } = meters || {};
      const { meter: buyMeter } = buy;
      const { meter: sellMeter } = sell;

      const buyTimestampList = buyMeter?.data?.map((datum) => datum?.timestamp) || [];
      const sellTimestampList = sellMeter?.data?.map((datum) => datum?.timestamp) || [];
      buyTimestamps = [...buyTimestamps, ...buyTimestampList];
      sellTimestamps = [...sellTimestamps, ...sellTimestampList];

      mainData.buy.rules = {
        ...mainData.buy.rules,
        ...filterRulesByTradePointId(rules, tradePointId, BUYER),
      };
      mainData.sell.rules = {
        ...mainData.sell.rules,
        ...filterRulesByTradePointId(rules, tradePointId, SELLER),
      };
      mainData.meters = {
        ...mainData.meters, [meters.id]: { ...meterData, propertyId: property?.id },
      };
      mainData.properties = { ...mainData.properties, [property.id]: property };
    });
  }

  // timestamps to form the object keys
  const timestamps = {
    buy: [...new Set(buyTimestamps)],
    sell: [...new Set(sellTimestamps)],
  };

  // looping over all the timestamps
  DIRECTIONS.forEach((direction) => {
    timestamps[direction].forEach((timestamp) => {
      extractList.forEach((datum) => {
        const {
          id: meterId, propertyId,
          tradePointId,
        } = datum?.meters || {};
        const propertyRegion = mainData.properties[propertyId]?.publicHolidayRegion;
        const { trades, meter } = datum[direction] || {};
        const { data: meterData, aggregation } = meter || {};

        if (!aggregation) return mainData;

        const x = getTimeStamp(aggregation, timestamp);
        const finalTimestamp = x.ts;

        // assigning default values
        const {
          meterDataAggregates: defaultMeter = {},
          tradeSetSummaries: defaultTrades = {},
          untradedDataAggregates: defaultUntraded = {},
        } = mainData[direction].data[finalTimestamp] || {};

        mainData[direction].data[finalTimestamp] = {
          meterDataAggregates: defaultMeter,
          tradeSetSummaries: defaultTrades,
          untradedDataAggregates: defaultUntraded,
        };
        // Add tradeset summary grouped by rule id
        if (trades && trades.length > 0) {
          trades.forEach((trade) => {
            const { key, data: tradeData } = trade || {};

            const { ruleId } = key || {};
            if (ruleId && tradeData) {
              const filteredData = tradeData.filter((d) => d?.range?.finish === timestamp);
              let tradeDatum = {
                value: 0, volume: 0, carbon: 0, counterfactual: NaN, meterId,
              };
              let filteredDatum = filteredData && filteredData[0] ? filteredData[0] : '';
              if (filteredDatum) {
                const { directions: tradeDirections, types: tradeTypes, volume } = filteredDatum;
                const tradeDirection = tradeDirections?.length === 1 ? tradeDirections[0] : '';
                const type = tradeTypes?.length === 1 ? tradeTypes[0] : '';

                filteredDatum = {
                  ...filteredDatum,
                  direction: tradeDirection,
                  type,
                  meterId,
                  tradePointId,
                };
                let carbon = 0;
                if (direction === BUY) {
                  const carbonDataProps = {
                    dataType: TRADE,
                    timestamp: x,
                    energy: isNumber(volume) ? Big(volume) : Big(0),
                    propertyRegion,
                    direction: tradeDirection,
                    type,
                  };
                  carbon = calculateCarbonData(carbonDataProps);
                }
                const filteredRules = mainData[direction].rules;

                const tradeSummaryInput = {
                  direction: TRADE_DIRECTIONS_LABEL[direction],
                  range: filteredDatum.range,
                  tradePointId,
                  volume,
                };
                const counterfactual = type === TRADE_TYPE_RESIDUAL
                  ? NaN : getCounterFactualValue(tradeSummaryInput, Object.values(filteredRules));

                filteredDatum = {
                  ...filteredDatum,
                  carbon: Number(carbon),
                  counterfactual,
                };

                // eslint-disable-next-line no-unused-vars
                const { directions, types, ...finalDatum } = filteredDatum; // remove unused keys
                tradeDatum = finalDatum;
              }
              mainData[direction].data[finalTimestamp].tradeSetSummaries[ruleId] = tradeDatum;
            }
          });
        }

        // add meter data grouped by meter trade point id
        if (meterData && meterId) {
          const filteredData = meterData.filter((d) => d?.timestamp === timestamp) || {};
          let meterDatum = filteredData && filteredData[0] ? filteredData[0] : {};
          const { value } = meterDatum;
          let carbon = 0;

          if (direction === BUY) {
            const carbonDataProps = {
              dataType: METER,
              timestamp: x,
              energy: isNumber(value) ? Big(value) : Big(0),
              propertyRegion,
              direction: TRADE_DIRECTIONS_LABEL[direction],
            };
            carbon = calculateCarbonData(carbonDataProps);
          }
          meterDatum = {
            ...meterDatum,
            carbon: Number(carbon),
          };
          mainData[direction].data[finalTimestamp].meterDataAggregates[meterId] = meterDatum;
        }

        return mainData;
      });
    });

    // Add untraded energy
    const tempData = { ...mainData };
    Object.entries(tempData[direction].data)?.forEach(([timestamp, info]) => {
      const { meterDataAggregates, tradeSetSummaries } = info;
      const energy = Big(0);
      let energyByTradePointId = {};
      const untradedDataBymeter = {};

      Object.keys(meterDataAggregates)?.forEach((meterId) => {
        if (!meterDataAggregates[meterId]) return;

        const meter = meterDataAggregates[meterId];
        const { value } = meter;
        energyByTradePointId = isNumber(value)
          ? { ...energyByTradePointId, [meterId]: energy.plus(value) }
          : { ...energyByTradePointId, [meterId]: energy };
        untradedDataBymeter[meterId] = { volume: energyByTradePointId[meterId] };
      });
      Object.keys(tradeSetSummaries)?.forEach((tradeId) => {
        if (tradeSetSummaries[tradeId]) {
          const { volume, meterId } = tradeSetSummaries[tradeId];
          const meterData = mainData.meters[meterId];
          if (meterData?.aggregation) {
            const tradedEnergyVolume = isNumber(volume) ? volume : 0;
            const untradedEnergyVolume = untradedDataBymeter[meterId].volume;
            if (untradedEnergyVolume > 0) {
              untradedDataBymeter[meterId].volume = untradedEnergyVolume
                .minus(tradedEnergyVolume);
            }
          }
        }
      });
      const allRules = mainData[direction].rules;
      const directionLabel = TRADE_DIRECTIONS_LABEL[direction];
      Object.keys(untradedDataBymeter)?.forEach((meterId) => {
        const { volume: untradedVolume } = untradedDataBymeter[meterId];
        const filteredMeter = mainData.meters[meterId];
        const { propertyId, tradePointId } = filteredMeter || {};
        const region = mainData.properties[propertyId].publicHolidayRegion || null;
        const carbonDataProps = {
          dataType: TRADE,
          timestamp,
          energy: Number.isNaN(untradedVolume) ? Big(0) : untradedVolume,
          direction: directionLabel,
          type: TRADE_TYPE_RESIDUAL,
          propertyRegion: region,
        };
        const untradedValue = getCounterFactualValue({
          direction: directionLabel,
          range: { start: timestamp, finish: timestamp },
          tradePointId,
          volume: untradedVolume,
        }, Object.values(allRules));

        const carbonData = calculateCarbonData(carbonDataProps);
        untradedDataBymeter[meterId] = {
          ...untradedDataBymeter[meterId],
          carbon: carbonData,
          value: untradedValue,
        };
      });
      mainData[direction].data[timestamp].untradedDataAggregates = untradedDataBymeter;
    });
  });

  mainData.hasCounterfactual = hasCounterfactual(mainData);
  mainData.timezone = timezone || TIME_ZONE_SYSTEM;

  return mainData;
};
