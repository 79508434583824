import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import AcceptNominatedTradeMutation from 'src/mutations/AcceptNominatedTradeMutation';
import CancelNominatedTradeMutation from 'src/mutations/CancelNominatedTradeMutation';
import RejectNominatedTradeMutation from 'src/mutations/RejectNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import {
  TRADE_RULE_ACCEPT, TRADE_RULE_CANCEL, TRADE_RULE_REJECT, TRADE_RULE_STATE_PROPOSED,
  USER_TYPE_CUSTOMER,
} from 'src/util/constants';
import isActive from 'src/util/isActive';

import { getLastStep, tradeRuleActions } from '../TradeRuleHelpers';
import TradeRuleTimelineStepProposed from '../TradeRuleTimeline/TradeRuleTimelineStepProposed';

const getRules = (rules) => {
  if (rules) {
    const { buy, sell } = rules;
    if (buy && buy.length > 0) {
      return buy[0];
    }
    if (sell && sell.length > 0) {
      return sell[0];
    }
  }
  return {};
};

const tradeRuleButtonTitle = (action) => {
  if (!action) {
    return null;
  }
  const title = action.toLowerCase().split('_')?.splice(-1)[0];
  if (title) {
    return title.charAt(0).toUpperCase() + title.slice(1);
  }
  return null;
};

class TradeRuleShow extends React.Component {
  static tradeRules(id, proposal) {
    const rules = { buy: [], sell: [], count: 0 };

    if (!proposal) { return rules; }

    if (proposal.state !== TRADE_RULE_STATE_PROPOSED) { return rules; }

    const actions = [];
    if (proposal.buyer.user.id === id) {
      if (proposal.buyer.tradePoint.id === '') {
        actions.push(TRADE_RULE_REJECT);
        actions.push(TRADE_RULE_ACCEPT);
      } else {
        actions.push(TRADE_RULE_CANCEL);
      }
    }
    if (proposal.seller.user.id === id) {
      if (proposal.seller.tradePoint.id === '') {
        actions.push(TRADE_RULE_REJECT);
        actions.push(TRADE_RULE_ACCEPT);
      } else {
        actions.push(TRADE_RULE_CANCEL);
      }
    }

    if (proposal.seller.user.id === proposal.proposedBy.id) {
      rules.sell.push({ ...proposal, actions });
    } else {
      rules.buy.push({ ...proposal, actions });
    }
    return rules;
  }

  // eslint-disable-next-line class-methods-use-this -- consistency in handlers.
  handleNominatedTradeMutationFailure = (error) => {
    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handlers.
  handleCancelNominatedTradeMutationSuccess = (response) => {
    const { id } = response.cancelNominatedTrade;
    const { intl } = this.props;
    const successMessage = intl.formatMessage({ id: 'trade_rule.trade_rule_customer_show.response_message.cancelled', defaultMessage: 'Cancelled peer-to-peer trade rule proposal with id: {id}.' }, { id });

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      successMessage,
    );
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handlers.
  handleRejectNominatedTradeMutationSuccess = (response) => {
    const { id } = response.rejectNominatedTrade;
    const { intl } = this.props;
    const successMessage = intl.formatMessage({ id: 'trade_rule.trade_rule_customer_show.response_message.rejected', defaultMessage: 'Rejected peer-to-peer trade rule proposal with id: {id}.' }, { id });

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      successMessage,
    );
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handlers.
  handleAcceptNominatedTradeMutationSuccess = (response) => {
    const { id } = response.acceptNominatedTrade;
    const { intl } = this.props;
    const successMessage = intl.formatMessage({ id: 'trade_rule.trade_rule_customer_show.response_message.accepted', defaultMessage: 'Accepted peer-to-peer trade rule proposal with id: {id}.' }, { id });

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      successMessage,
    );
  };

  tradeRuleAction = (rule, action, opts = {}) => {
    const { id } = rule;
    switch (action) {
      case TRADE_RULE_CANCEL:
        CancelNominatedTradeMutation(
          id,
          this.handleCancelNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
        );
        break;
      case TRADE_RULE_REJECT:
        RejectNominatedTradeMutation(
          id,
          this.handleRejectNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
        );
        break;
      case TRADE_RULE_ACCEPT:
        AcceptNominatedTradeMutation(
          id,
          opts.tradePointId,
          this.handleAcceptNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
        );
        break;
      default:
        FlashesStore.flash(
          FlashesStore.ERROR,
          `Action ${action} is not possible`,
        );
    }
  };

  tradeRuleActionButton = (rule) => {
    const {
      id, state, buyer, seller,
    } = rule;

    const counterPartyTradePointId = [buyer?.tradePoint?.id, seller?.tradePoint?.id]
      .filter(Boolean)[0];

    if (state !== TRADE_RULE_STATE_PROPOSED || !counterPartyTradePointId) {
      return null;
    }

    const { viewer } = this.props;
    const { meters: meterConn } = viewer;
    const meterNodes = (!meterConn || !meterConn.edges)
      ? [] : meterConn.edges.map((edge) => (edge.node));

    const options = meterNodes.reduce((acc, el) => {
      const {
        identifier, title, tradePointId, active,
      } = el;
      if (tradePointId === counterPartyTradePointId) { return acc; }
      if (!el.property || !el.property.id) { return acc; }
      acc[el.property.id] = acc[el.property.id] || { property: el.property, tradePoints: [] };
      acc[el.property.id].tradePoints.push({
        identifier, title, tradePointId, active,
      });
      return acc;
    }, {});

    const buttons = [];

    rule?.actions?.forEach((action) => {
      if (action === TRADE_RULE_ACCEPT) {
        buttons.push(
          <UncontrolledButtonDropdown key={`${id}-${action}`}>
            <DropdownToggle caret>
              Accept
            </DropdownToggle>
            <DropdownMenu>
              {Object.values(options).map((opts) => {
                const { property, tradePoints } = opts;
                return (
                  <>
                    <DropdownItem header key={`${id}-${action}-${property.id}`}>{property.title}</DropdownItem>
                    {tradePoints.map((tp) => (
                      <DropdownItem
                        onClick={() => (
                          this.tradeRuleAction(rule, action, { tradePointId: tp.tradePointId })
                        )}
                        disabled={!isActive(tp.active)}
                        key={`${id}-${action}-${tp.tradePointId}`}
                      >
                        {`${tp.title} (${tp.identifier})`}
                      </DropdownItem>
                    ))}
                  </>
                );
              })}
            </DropdownMenu>
          </UncontrolledButtonDropdown>,
        );
        return;
      }
      buttons.push(
        <Button className="me-1" onClick={() => this.tradeRuleAction(rule, action)} key={`${id}-${action}`}>
          {tradeRuleButtonTitle(action)}
        </Button>,
      );
    });

    return tradeRuleActions(buttons);
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, router, viewer } = this.props;
    const { rule: tradeRule, viewerUser } = viewer;
    const { id } = tradeRule;
    const { id: userId } = viewerUser;
    const { formatMessage } = intl;

    const meterNodes = viewer.meters.edges.map((edge) => edge.node);
    const metersMap = {};
    meterNodes.forEach((item) => {
      const { tradePointId } = item;
      metersMap[tradePointId] = item;
    });

    if (!viewerUser || viewerUser.type !== USER_TYPE_CUSTOMER) {
      FlashesStore.flash(FlashesStore.ERROR, <FormattedMessage id="trade_rule_customer_proposals.view.restriction.text" defaultMessage="only customers can view their proposals" />);
      router.replace('/properties');
    }

    const rules = TradeRuleShow.tradeRules(userId, tradeRule);

    const {
      tradeType, clauses, buyer, seller, proposedAt, proposedBy,
    } = tradeRule;

    const lastStep = getLastStep(tradeRule);
    const finalRules = getRules(rules);
    const buttons = this.tradeRuleActionButton(finalRules);
    const pageTitle = formatMessage({ id: 'trade_rule_customer_show.page_title', defaultMessage: 'Enosi - Trade Rule' });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: <FormattedMessage id="common.entities.trade_rules.label" defaultMessage="Trade Rules" />, path: '/trade-rules' },
            { name: id },
          ]}
        />

        <Heading alias={<FormattedMessage id="trade_rule.header" defaultMessage="Trade Rule" />} context={`${formatMessage({ id: 'common.entities.rule.label', defaultMessage: 'Rule' })} ${id}`} />

        {buttons
          && (
            <ol className="m-0 p-0">
              <TradeRuleTimelineStepProposed
                metersMap={metersMap}
                ruleId={id}
                tradeType={tradeType}
                clauses={clauses}
                buyer={buyer}
                seller={seller}
                timestamp={proposedAt}
                user={proposedBy}
                isCurrentStep={lastStep === TRADE_RULE_STATE_PROPOSED}
                hasNextStep={lastStep !== TRADE_RULE_STATE_PROPOSED || !!buttons}
              />
              {buttons}
            </ol>
          )}
      </>
    );
  }
}

TradeRuleShow.propTypes = {
  viewer: PropTypes.shape({
    viewerUser: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    }),
    meters: PropTypes.shape({
      edges: PropTypes.arrayOf({
        node: PropTypes.shape({
          id: PropTypes.string,
          identifier: PropTypes.string,
          tradePointId: PropTypes.string,
          active: PropTypes.shape({
            start: PropTypes.number,
            finish: PropTypes.number,
          }),
        }),
      }),
    }),
    rule: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

TradeRuleShow.defaultProps = {};

export default injectIntl(createFragmentContainer(
  TradeRuleShow,
  {
    viewer: graphql`
      fragment TradeRuleCustomerShow_viewer on Viewer @argumentDefinitions(
        id: { type: "ID!"}
      ) {
        id
        viewerUser { id type }
        meters(first: 500) {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              active { start finish }
              property {
                id
                title
                active { start finish }
              }
            }
          }
        }
        rule(id: $id) {
          id
          tradeType
          state
          buyer {
            user { id email givenName familyName }
            community { id title }
            residualId
            tradePoint {
              id
              type
              meter {
                id
                identifier
                title
                property {
                  id
                  title
                }
              }
            }
          }
          seller {
            user { id email givenName familyName }
            community { id title }
            residualId
            tradePoint {
              id
              type
              meter {
                id
                identifier
                title
                property {
                  id
                  title
                }
              }
            }
          }
          clauses {
            edges {
              node {
                price
              }
            }
          }
          proposedAt
          proposedBy { id email givenName familyName }
          acceptedAt
          acceptedBy { id email givenName familyName }
          rejectedAt
          rejectedBy { id email givenName familyName }
          cancelledAt
          cancelledBy { id email givenName familyName }
          closedAt
          closedBy { id email givenName familyName }
        }
      }
    `,
  },
));
