import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'd3-format';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { APIConfig } from 'src/config';
import {
  COLOR_EXPORT, COLOR_EXPORT_UNTRADED,
  COLOR_IMPORT, COLOR_IMPORT_UNTRADED,
} from 'src/enosikit/components/Chart/components/chartConstants';
import {
  COMMUNITY, CONTRACTED, NA_SYMBOL, NOMINATED, PLATFORM_MODE_REBATE,
  RESIDUAL,
} from 'src/util/constants';
import { i18nDecimalFormat } from 'src/util/i18n/handler';
import isNumber from 'src/util/math';

export const CardsLabel = styled.p.attrs({
  className: 'mb-2',
})`
  color: #adb5bd;
  font-size: 0.8em;
  margin: 0;
  padding: 0
`;

/**
 * Returns the avatar shown as part of the trade and meter cards
 * @param {object} user
 * @param {string} ring
 * @param {NOMINATED | CONTRACTED | COMMUNITY | RESIDUAL} tradeType
 * @returns {React.ReactElement} - avatar used in meter and trade cards
 */
export const avatar = (user, ring, tradeType) => {
  const baseStyle = {
    background: '#fff', borderRadius: '50%', height: '4em', width: '4em',
  };

  let avatarImage;
  if (user || tradeType === NOMINATED) {
    avatarImage = (
      <div className="property-card-user" style={{ ...baseStyle, background: '#fff' }}>
        <FontAwesomeIcon icon={faUserCircle} size="1x" style={{ height: '100%', width: '100%' }} />
      </div>
    );
  } else {
    avatarImage = <div style={{ ...baseStyle, background: '#fff' }} />;
  }
  return (
    <div style={{ borderRadius: '50%', padding: '0.25rem', background: ring }}>
      {avatarImage}
    </div>
  );
};

/**
 * Determines the card opacity based on the user selection
 * @param {string} key - normally tradeid or a combination of trade and meterid
 * @param {Array} hoverKeys
 * @param {Array} selectedKeys
 * @returns {string} - opacity for the cards.
 */
export const opacity = (key, hoverKeys, selectedKeys) => {
  // Default state
  if (hoverKeys.length === 0 && selectedKeys.length === 0) { return '100%'; }
  // Hovering
  if (hoverKeys.includes(key)) { return '100%'; }
  // Selected - with and without hovering
  if (selectedKeys.includes(key)) { return hoverKeys.length === 0 ? '100%' : '40%'; }
  // Not selected
  return '10%';
};

/**
 * Ring builds the ring details around the element.
 * @param {object} buy
 * @param {number} buy.count
 * @param {object} sell
 * @param {number} sell.count
 * @param {boolean} isUntraded
 * @returns {string} the CSS string for the ring.
 */
export const ring = (buy = { count: 0 }, sell = { count: 0 }, isUntraded = false) => {
  if (!buy && !sell) { return 'none'; }

  const buyColor = isUntraded ? COLOR_IMPORT_UNTRADED : COLOR_IMPORT;
  const sellColor = isUntraded ? COLOR_EXPORT_UNTRADED : COLOR_EXPORT;

  if (buy?.count > 0 && sell?.count > 0) { return `linear-gradient(to right, ${buyColor} 50%, ${sellColor} 50%)`; }
  if (buy?.count > 0) { return buyColor; }
  if (sell?.count > 0) { return sellColor; }

  return 'none';
};

/**
 * Provides the meter identifier for the meter label
 * @param {string} title
 * @param {string} identifier
 * @returns {string} - meter identifier.
 */
export const meterIdentifier = (title, identifier) => {
  if (!identifier) {
    return '';
  }

  return title ? ` (${identifier})` : identifier;
};

/**
 * Build and return the content for the meter cards.
 * @param {React.ReactElement} cardLabel - cards label
 * @param {number} [buyVolume=0]
 * @param {number} [sellVolume=0]
 * @returns {React.ReactElement} - meter data container.
 */
export const meterContent = (cardLabel, buyVolume = 0, sellVolume = 0) => (
  <div className="mb-2">
    <h5>{cardLabel}</h5>
    <h6>
      <FormattedMessage id="property.property_show.chart_cards.exports" defaultMessage="Exports" />
    </h6>
    <div className="mb-2">{`${i18nDecimalFormat(format('.4s')(sellVolume))}Wh`}</div>
    <h6 className="mb-2">
      <FormattedMessage id="property.property_show.chart_cards.imports" defaultMessage="Imports" />
    </h6>
    <div className="mb-2">{`${i18nDecimalFormat(format('.4s')(buyVolume))}Wh`}</div>
  </div>
);

const tradeLabel = (dir) => (dir === 'sell' ? <FormattedMessage id="common.entities.exports.label" defaultMessage="Exports" />
  : <FormattedMessage id="common.entities.imports.label" defaultMessage="Imports" />);

/**
 * Build and return the content for trade cards.
 * @param {string} tradeType The trade type.
 * @param {object} cardDatum The card's datum
 * @param {object} cardDatum.buy The card's buy details
 * @param {object} cardDatum.buy.value The card's buy details
 * @param {object} cardDatum.buy.volume The card's buy details
 * @param {object} cardDatum.sell The card's buy details
 * @param {object} cardDatum.sell.value The card's buy details
 * @param {object} cardDatum.sell.volume The card's buy details
 * @param {React.ReactElement} cardLabel The label UI for the cards
 * @returns {React.ReactElement} - trade data container.
 */
export const tradeContent = (tradeType, cardDatum, cardLabel) => (
  <>
    <h5>{cardLabel}</h5>
    {['sell', 'buy'].map((dir) => {
      if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
        return (
          <React.Fragment key={dir}>
            <h6>{tradeLabel(dir)}</h6>
            <div className="mb-2">
              {`${i18nDecimalFormat(format('.4s')(cardDatum[dir].volume))}Wh`}
            </div>
            {tradeType !== RESIDUAL && (
              <>
                <h6><FormattedMessage id="property.property_show.chart_cards.discount_amount" defaultMessage="Discount Amount" /></h6>
                <div className="mb-2">
                  {/*
                  "common.currency.symbol"
                  NOTE: this needs to be changed –
                  the formatting (format('.2f')) is dependent on config.
                  */}
                  <FormattedMessage id="common.currency.symbol" defaultMessage="{n, number, ::currency/AUD}" values={{ n: format('.2f')(cardDatum[dir].value) }} />
                </div>
              </>
            )}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={dir}>
          <h6>{tradeLabel(dir)}</h6>
          <div className="mb-2">
            {/*
            NOTE: this needs to be changed – the formatting (format('.2f')) is dependent on config.
            */
              (cardDatum[dir].value === 0 || (cardDatum[dir].value && isNumber(cardDatum[dir].value))) ? (<FormattedMessage id="common.currency.symbol" defaultMessage="{n, number, ::currency/AUD}" values={{ n: format('.2f')(cardDatum[dir].value) }} />) : NA_SYMBOL
            }
          </div>
          <div className="mb-2">
            {`${i18nDecimalFormat(format('.4s')(cardDatum[dir].volume))}Wh`}
          </div>
        </React.Fragment>
      );
    })}
  </>
);
