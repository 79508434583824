import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Badge, CardText } from 'reactstrap';

import BadgeTradePointType from 'src/components/Badge/BadgeTradePointType';
import UUID from 'src/components/UUID';
import { APIConfig } from 'src/config';
import Time from 'src/enosikit/components/Time/components/Time';
import {
  PLATFORM_MODE_REBATE, PLATFORM_MODE_TRADE,
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL,
  TRADE_POINT_TYPE_COMMUNITY, TRADE_POINT_TYPE_RESIDUAL,
} from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import username from 'src/util/decorators/username';
import { getStringAndNumericFormattedDate } from 'src/util/i18n/helpers';
import { tradeRulePriceRange } from 'src/util/tradeRule';

/**
 * Delivers a link to a property for a trade point, returning null if no link can be generated.
 * @param {object} tradePoint
 * @param {boolean} isPopover
 * @returns {React.ReactElement} - property link.
 */
export const getPropertyLink = (tradePoint, isPopover) => {
  const property = tradePoint?.meter?.property;
  if (!property) {
    return null;
  }

  const { id, title } = property;
  const label = !title ? id : title;

  if (!label || label === '') {
    return null;
  }

  if (isPopover) {
    return <strong>{label}</strong>;
  }

  if (id) {
    const redirectLink = `/properties/${id}`;

    return (
      <span>
        <a href={redirectLink}>{label}</a>
      </span>
    );
  }

  return <span>{label}</span>;
};

/**
 * Build a trade point label for inline use.
 * @param {object} tradePoint
 * @returns {React.ReactElement} - trade point label.
 */
export const getTradePointInlineLabel = (tradePoint) => {
  if (!tradePoint) { return null; }

  const { id, meter, type } = tradePoint;

  const idLabel = !id ? null : (
    <>
      (
      <UUID uuid={id} />
      )
    </>
  );

  if (type === TRADE_POINT_TYPE_RESIDUAL) {
    const retailerLabel = (
      <span className="trade-point-title">
        <FormattedMessage id="helpers.trade_helpers.get_trade_point_inline_label.trade_point_type_residual.with_id.retailer.label" defaultMessage="Retailer" />
      </span>
    );

    if (idLabel) {
      return (
        <FormattedMessage
          id="helpers.trade_helpers.get_trade_point_inline_label.trade_point_type_residual.with_id.label"
          defaultMessage="{retailerLabel} {idLabel}"
          values={{
            idLabel,
            retailerLabel,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_inline_label.trade_point_type_residual.no_id.label"
        defaultMessage="{retailerLabel}"
        values={{
          retailerLabel,
        }}
      />
    );
  }

  if (type === TRADE_POINT_TYPE_COMMUNITY) {
    const communityLabel = (
      <span className="trade-point-title">
        <FormattedMessage id="helpers.trade_helpers.get_trade_point_inline_label.trade_point_type.community.label" defaultMessage="Community" />
      </span>
    );
    if (idLabel) {
      return (
        <FormattedMessage
          id="helpers.trade_helpers.get_trade_point_inline_label.trade_point_type.community.with_id.label"
          defaultMessage="{communityLabel} {idLabel}"
          values={{
            communityLabel,
            idLabel,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_inline_label.trade_point_type.community.no_id.label"
        defaultMessage="{communityLabel}"
        values={{
          communityLabel,
        }}
      />
    );
  }

  if (!meter) {
    const tradePointLabel = (
      <span className="trade-point-title">
        <FormattedMessage id="helpers.trade_helpers.get_trade_point_inline_label.trade_point.label" defaultMessage="Trade Point" />
      </span>
    );
    if (idLabel) {
      return (
        <FormattedMessage
          id="helpers.trade_helpers.get_trade_point_inline_label.trade_point.with_id.label"
          defaultMessage="{tradePointLabel} {idLabel}"
          values={{
            idLabel,
            tradePointLabel,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_inline_label.trade_point.no_id.label"
        defaultMessage="{tradePointLabel}"
        values={{
          tradePointLabel,
        }}
      />
    );
  }

  const { title, identifier } = meter;
  const propertyLink = getPropertyLink(tradePoint);
  const meterTitle = <span className="trade-point-title">{title}</span>;

  if (!idLabel && !propertyLink) {
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_inline_label.no_id_and_no_property.label"
        defaultMessage="{meterTitle} ({identifier})"
        values={{
          identifier,
          meterTitle,
        }}
      />
    );
  }
  if (!idLabel && propertyLink) {
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_inline_label.no_id_and_with_property.label"
        defaultMessage="{meterTitle} ({identifier}) at {propertyLink}"
        values={{
          identifier,
          meterTitle,
          propertyLink,
        }}
      />
    );
  }
  if (idLabel && !propertyLink) {
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_inline_label.with_id_and_no_property.label"
        defaultMessage="{meterTitle} ({identifier}) {idLabel}"
        values={{
          identifier,
          idLabel,
          meterTitle,
        }}
      />
    );
  }

  if (idLabel && propertyLink) {
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_inline_label.with_id_and_with_property.label"
        defaultMessage="{meterTitle} ({identifier}) at {propertyLink} {idLabel}"
        values={{
          identifier,
          idLabel,
          meterTitle,
          propertyLink,
        }}
      />
    );
  }
  return null;
};

/**
 * Build a trade point label for the trade rule table.
 * @param {object} tradePoint
 * @returns {React.ReactElement} - trade point label.
 */
export const getTradePointTradeRuleTableLabel = (tradePoint) => {
  if (!tradePoint) { return null; }

  const { id, meter, type } = tradePoint;
  const idLabel = !id ? null : (
    <>
      (
      <UUID uuid={id} />
      )
      <br />
    </>
  );
  const tradePointType = <BadgeTradePointType type={type} />;

  if (type === TRADE_POINT_TYPE_RESIDUAL) {
    const retailerLabel = (
      <>
        <strong><FormattedMessage id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.trade_point_type_residual.retailer.label" defaultMessage="Retailer" /></strong>
        <br />
      </>
    );

    if (idLabel) {
      return (
        <FormattedMessage
          id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.trade_point_type_residual.with_id.label"
          defaultMessage="{retailerLabel} {idLabel} {tradePointType}"
          values={{
            idLabel,
            retailerLabel,
            tradePointType,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.trade_point_type_residual.no_id.label"
        defaultMessage="{retailerLabel} {tradePointType}"
        values={{
          retailerLabel,
          tradePointType,
        }}
      />
    );
  }

  if (type === TRADE_POINT_TYPE_COMMUNITY) {
    const communityLabel = (
      <>
        <strong>
          <FormattedMessage id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.trade_point_type_community.community.label" defaultMessage="Community" />
        </strong>
        <br />
      </>
    );
    if (idLabel) {
      return (
        <FormattedMessage
          id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.trade_point_type_community.with_id.label"
          defaultMessage="{communityLabel} {idLabel} {tradePointType}"
          values={{
            communityLabel,
            idLabel,
            tradePointType,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.trade_point_type_community.no_id.label"
        defaultMessage="{communityLabel} {tradePointType}"
        values={{
          communityLabel,
          tradePointType,
        }}
      />
    );
  }

  if (!meter) {
    const tradePointLabel = (
      <>
        <strong>
          <FormattedMessage id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.trade_point.label" defaultMessage="Trade Point" />
        </strong>
        <br />
      </>
    );
    if (idLabel) {
      return (
        <FormattedMessage
          id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.trade_point.with_id.label"
          defaultMessage="{tradePointLabel} {idLabel} {tradePointType}"
          values={{
            idLabel,
            tradePointLabel,
            tradePointType,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.trade_point.no_id.label"
        defaultMessage="{tradePointLabel} {tradePointType}"
        values={{
          tradePointLabel,
          tradePointType,
        }}
      />
    );
  }

  const { title, identifier } = meter;
  const propertyLink = getPropertyLink(tradePoint, false);

  const finalPropertyLink = (
    <>
      {propertyLink}
      <br />
    </>
  );
  const meterTitle = <strong>{title}</strong>;
  const meterIdentifier = (
    <>
      {`(${identifier})`}
      <br />
    </>
  );

  if (!idLabel && !propertyLink) {
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.no_id_and_no_property.label"
        defaultMessage="{meterTitle} {meterIdentifier} {tradePointType}"
        values={{
          meterIdentifier,
          meterTitle,
          tradePointType,
        }}
      />
    );
  }
  if (!idLabel && propertyLink) {
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.no_id_and_with_property.label"
        defaultMessage="{meterTitle} {meterIdentifier} {propertyLink} {tradePointType}"
        values={{
          meterIdentifier,
          meterTitle,
          tradePointType,
          propertyLink: finalPropertyLink,
        }}
      />
    );
  }
  if (idLabel && !propertyLink) {
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.with_id_and_no_property.label"
        defaultMessage="{meterTitle} {meterIdentifier} {idLabel} {tradePointType}"
        values={{
          meterIdentifier,
          idLabel,
          meterTitle,
          tradePointType,
        }}
      />
    );
  }

  if (idLabel && propertyLink) {
    return (
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_point_trade_rule_table_label.with_id_and_with_property.label"
        defaultMessage="{meterTitle} {meterIdentifier} {propertyLink} {idLabel} {tradePointType}"
        values={{
          meterIdentifier,
          idLabel,
          meterTitle,
          propertyLink: finalPropertyLink,
          tradePointType,
        }}
      />
    );
  }
  return null;
};

/**
 * Build a label for a trade rule meter.
 * @param {object} trader
 * @param {object} counterparty
 * @param {string} direction
 * @param {boolean} isPopover
 * @returns {React.ReactElement} - trade rule meter label.
 */
export const getTradeRuleMeterLabel = (
  trader,
  counterparty,
  direction,
  isPopover,
) => {
  if (!trader) {
    return null;
  }
  const { tradePoint } = trader;
  const { id: tradePointId, meter } = tradePoint || {};
  const { id: counterpartyTradepointId } = counterparty?.tradePoint || {};

  const counterPartyCheck = tradePointId === counterpartyTradepointId;
  const finalCounterparty = counterPartyCheck ? '' : counterparty;

  const { title, identifier } = meter || {};
  const propertyLink = getPropertyLink(tradePoint, isPopover);
  const {
    title: counterpartyTitle,
    identifier: counterpartyIdentifier,
  } = finalCounterparty?.tradePoint?.meter || {};
  const { tradePoint: counterpartyTradePoint } = finalCounterparty || {};
  const counterpartyPropertyLabel = getPropertyLink(counterpartyTradePoint, isPopover);
  const counterpartyMeterExist = counterpartyTitle && counterpartyIdentifier;

  if (meter) {
    if (!propertyLink) {
      return (
        <FormattedMessage
          id="helpers.trade_helpers.get_trade_rule_meter_label.no_property.label"
          defaultMessage="{title} ({identifier})"
          values={{
            identifier,
            title,
          }}
        />
      );
    }
    if (propertyLink && !counterpartyMeterExist) {
      return (
        <FormattedMessage
          id="helpers.trade_helpers.get_trade_rule_meter_label.with_property.label"
          defaultMessage="{title} ({identifier}) at {propertyLink}"
          values={{
            identifier,
            propertyLink,
            title,
          }}
        />
      );
    }
    if (propertyLink && counterpartyMeterExist && !counterpartyPropertyLabel) {
      if (direction === TRADE_DIRECTION_BUY) {
        return (
          <FormattedMessage
            id="helpers.trade_helpers.get_trade_rule_meter_label.trade_direction_buy.with_property_and_counterparty_meter.label"
            defaultMessage="{title} ({identifier}) at {propertyLink} from {counterpartyTitle} ({counterpartyIdentifier})"
            values={{
              counterpartyIdentifier,
              counterpartyTitle,
              identifier,
              propertyLink,
              title,
            }}
          />
        );
      }
      if (direction === TRADE_DIRECTION_SELL) {
        return (
          <FormattedMessage
            id="helpers.trade_helpers.get_trade_rule_meter_label.trade_direction_sell.with_property_and_counterparty_meter.label"
            defaultMessage="{title} ({identifier}) at {propertyLink} to {counterpartyTitle} ({counterpartyIdentifier})"
            values={{
              counterpartyIdentifier,
              counterpartyTitle,
              identifier,
              propertyLink,
              title,
            }}
          />
        );
      }
    }
    if (propertyLink && counterpartyMeterExist && counterpartyPropertyLabel) {
      if (direction === TRADE_DIRECTION_BUY) {
        return (
          <FormattedMessage
            id="helpers.trade_helpers.get_trade_rule_meter_label.trade_direction_buy.with_property_and_counterparty_meter_and_counterparty_property.label"
            defaultMessage="{title} ({identifier}) at {propertyLink} from {counterpartyTitle} ({counterpartyIdentifier}) at {counterpartyPropertyLabel}"
            values={{
              counterpartyIdentifier,
              counterpartyTitle,
              counterpartyPropertyLabel,
              identifier,
              propertyLink,
              title,
            }}
          />
        );
      }
      if (direction === TRADE_DIRECTION_SELL) {
        return (
          <FormattedMessage
            id="helpers.trade_helpers.get_trade_rule_meter_label.trade_direction_sell.with_property_and_counterparty_meter_and_counterparty_property.label"
            defaultMessage="{title} ({identifier}) at {propertyLink} to {counterpartyTitle} ({counterpartyIdentifier}) at {counterpartyPropertyLabel}"
            values={{
              counterpartyIdentifier,
              counterpartyTitle,
              counterpartyPropertyLabel,
              identifier,
              propertyLink,
              title,
            }}
          />
        );
      }
    }
  }
  return null;
};

/**
 * Build the content that needs to be shown inside the trade popover for untraded energy.
 * @param {import('react-intl').IntlShape} intl - i18n intl object
 * @param {string} title -  property title
 * @returns {React.ReactElement} - popover content.
 */
export const getUntradedContent = (intl, title) => {
  if (!title) {
    return (
      intl.formatMessage({
        id: 'helpers.trade_helpers.get_untraded_content.no_title.details',
        defaultMessage: 'Untraded energy at this property',
      })

    );
  }

  return (
    intl.formatMessage(
      {
        id: 'helpers.trade_helpers.get_untraded_content.title.details',
        defaultMessage: 'Untraded energy at {title}',
      },
      { title: <strong>{title}</strong> },
    )

  );
};

/**
 * Build the content that needs to be shown inside the trade popover.
 * @param {string} direction
 * @param {object} tradeRule
 * @param {import('react-intl').IntlShape} intl
 * @returns {React.ReactElement} - trade rule content.
 */
export const getTradeRuleContent = (direction, tradeRule, intl) => {
  if (!direction || !tradeRule) {
    return null;
  }
  const {
    seller, buyer, acceptedAt, acceptedBy, proposedAt,
    proposedBy, id: ruleId,
  } = tradeRule || {};
  const isBuy = direction === TRADE_DIRECTION_BUY;
  const isSell = direction === TRADE_DIRECTION_SELL;
  const trader = isBuy ? buyer : seller;
  const counterParty = isBuy ? seller : buyer;
  const priceRange = tradeRulePriceRange(tradeRule);
  const { maximum, minimum } = priceRange;
  const mode = APIConfig().MODE;
  const rebateModeFlatPrice = mode === PLATFORM_MODE_REBATE && maximum === minimum;
  const rebateModeVariablePrice = mode === PLATFORM_MODE_REBATE && maximum !== minimum;
  const tradeModeBuyAndFlatPrice = isBuy && mode === PLATFORM_MODE_TRADE && maximum === minimum;
  const tradeModeBuyAndVariablePrice = isBuy && mode === PLATFORM_MODE_TRADE && maximum !== minimum;
  const tradeModeSellAndFlatPrice = isSell && mode === PLATFORM_MODE_TRADE && maximum === minimum;
  const tradeModeSellAndVariablePrice = isSell && mode === PLATFORM_MODE_TRADE
    && maximum !== minimum;

  return (
    <CardText>
      {isBuy && (
        <FormattedMessage
          id="helpers.trade_helpers.get_trade_rule_content_trade_direction_buy.details"
          defaultMessage="Buying for {meterLabel}"
          values={{
            meterLabel: getTradeRuleMeterLabel(trader, counterParty, direction, true),
          }}
        />
      )}
      {isSell && (
        <FormattedMessage
          id="helpers.trade_helpers.get_trade_rule_content_trade_direction_sell.details"
          defaultMessage="Selling from {meterLabel}"
          values={{
            meterLabel: getTradeRuleMeterLabel(trader, counterParty, direction, true),
          }}
        />
      )}
      <br />
      <FormattedMessage
        id="helpers.trade_helpers.get_trade_rule_content_proposal.details"
        defaultMessage="Proposed by {actor} on {timestamp}"
        values={{
          actor: <strong>{username(proposedBy)}</strong>,
          timestamp: <Time
            child={getStringAndNumericFormattedDate(intl, proposedAt)}
            unixTimestamp={proposedAt}
          />,
        }}
      />
      <br />
      {acceptedAt && (
        <FormattedMessage
          id="helpers.trade_helpers.get_trade_rule_content_trade_acceptance.details"
          defaultMessage="Accepted by {actor} on {timestamp}"
          values={{
            actor: <strong>{username(acceptedBy)}</strong>,
            timestamp: <Time
              child={getStringAndNumericFormattedDate(intl, acceptedAt)}
              unixTimestamp={acceptedAt}
            />,
          }}
        />
      )}
      <br />
      {rebateModeFlatPrice
        && (
          <FormattedMessage
            id="helpers.trade_helpers.get_trade_rule_content.trade_rule_price.flat.platform_mode_rebate.details"
            defaultMessage="Discount of {price} {units}"
            values={{

              // TO DO: Implement d3-format locale support (PT-1124)
              price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
              units:
                // eslint-disable-next-line react/jsx-indent
                <abbr title={intl.formatMessage({ id: 'helpers.trade_helpers.get_trade_rule_content.trade_rule_price.flat.platform_mode_rebate.details.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}>
                  <FormattedMessage id="helpers.trade_helpers.get_trade_rule_content.trade_rule_price.flat.platform_mode_rebate.details.energy_cost.abbr.label" defaultMessage="c/kWh" />
                </abbr>,
            }}
          />
        )}
      {rebateModeVariablePrice
        && (
          <FormattedMessage
            id="helpers.trade_helpers.get_trade_rule_content.trade_rule_price.variable.platform_mode_rebate.details"
            defaultMessage="Discount of {minimum} to {maximum} {units}"
            values={{

              // TO DO: Implement d3-format locale support (PT-1124)
              maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
              minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
              units:
                // eslint-disable-next-line react/jsx-indent
                <abbr title={intl.formatMessage({ id: 'helpers.trade_helpers.get_trade_rule_content.trade_rule_price.variable.platform_mode_rebate.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}>
                  <FormattedMessage id="helpers.trade_helpers.get_trade_rule_content.trade_rule_price.variable.platform_mode_rebate.energy_cost.abbr.label" defaultMessage="c/kWh" />
                </abbr>,
            }}
          />
        )}
      {tradeModeBuyAndFlatPrice
        && (
          <FormattedMessage
            id="helpers.trade_helpers.get_trade_rule_content.trade_direction_buy.trade_rule_price.flat.platform_mode_trade.details"
            defaultMessage="Buy price of {price} {units}"
            values={{

              // TO DO: Implement d3-format locale support (PT-1124)
              price: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
              units:
                // eslint-disable-next-line react/jsx-indent
                <abbr title={intl.formatMessage({ id: 'helpers.trade_helpers.get_trade_rule_content.trade_direction_buy.trade_rule_price.flat.platform_mode_trade.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}>
                  <FormattedMessage id="helpers.trade_helpers.get_trade_rule_content.trade_direction_buy.trade_rule_price.flat.platform_mode_trade.energy_cost.abbr.label" defaultMessage="c/kWh" />
                </abbr>,
            }}
          />
        )}
      {tradeModeBuyAndVariablePrice
        && (
          <FormattedMessage
            id="helpers.trade_helpers.get_trade_rule_content.trade_direction_buy.trade_rule_price.variable.platform_mode_trade.details"
            defaultMessage="Buy price of {minimum} to {maximum} {units}"
            values={{

              // TO DO: Implement d3-format locale support (PT-1124)
              maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
              minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
              units:
                // eslint-disable-next-line react/jsx-indent
                <abbr title={intl.formatMessage({ id: 'helpers.trade_helpers.get_trade_rule_content.trade_direction_buy.trade_rule_price.variable.platform_mode_trade.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}>
                  <FormattedMessage id="helpers.trade_helpers.get_trade_rule_content.trade_direction_buy.trade_rule_price.variable.platform_mode_trade.energy_cost.abbr.label" defaultMessage="c/kWh" />
                </abbr>,
            }}
          />
        )}
      {tradeModeSellAndFlatPrice
        && (
          <FormattedMessage
            id="helpers.trade_helpers.get_trade_rule_content.trade_direction_sell.trade_rule_price.flat.platform_mode_trade.details"
            defaultMessage="Sell price of {price} {units}"
            values={{

              // TO DO: Implement d3-format locale support (PT-1124)
              price: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
              units:
                // eslint-disable-next-line react/jsx-indent
                <abbr title={intl.formatMessage({ id: 'helpers.trade_helpers.get_trade_rule_content.trade_direction_sell.trade_rule_price.flat.platform_mode_trade.details.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}>
                  <FormattedMessage id="helpers.trade_helpers.get_trade_rule_content.trade_direction_sell.trade_rule_price.flat.platform_mode_trade.details.energy_cost.abbr.label" defaultMessage="c/kWh" />
                </abbr>,
            }}
          />
        )}
      {tradeModeSellAndVariablePrice
        && (
          <FormattedMessage
            id="helpers.trade_helpers.get_trade_rule_content.trade_direction_sell.trade_rule_price.variable.platform_mode_trade.details"
            defaultMessage="Sell price of {minimum} to {maximum} {units}"
            values={{

              // TO DO: Implement d3-format locale support (PT-1124)
              maximum: <strong>{convertEnergyPrice(maximum).toFixed(3)}</strong>,
              minimum: <strong>{convertEnergyPrice(minimum).toFixed(3)}</strong>,
              units:
                // eslint-disable-next-line react/jsx-indent
                <abbr title={intl.formatMessage({ id: 'helpers.trade_helpers.get_trade_rule_content.trade_direction_sell.trade_rule_price.variable.platform_mode_trade.energy_cost.abbr.title', defaultMessage: 'cents per kilowatt hour' })}>
                  <FormattedMessage id="helpers.trade_helpers.get_trade_rule_content.trade_direction_sell.trade_rule_price.variable.platform_mode_trade.energy_cost.abbr.label" defaultMessage="c/kWh" />
                </abbr>,
            }}
          />
        )}
      <Badge color="mid">{ruleId}</Badge>
    </CardText>
  );
};

/**
 * Returns a class name when there are no trade rules.
 * Used for targeting components while launching product tours
 * @param {object} tradeRules
 * @param {string} tradeType
 * @returns {string} - class name.
 */
export const emptyTradeRulesClassName = (tradeRules, tradeType) => {
  if (tradeRules && tradeType) {
    const { buy, sell } = tradeRules;
    if (buy?.length === 0 && sell?.length === 0) {
      return ` no-trade-rules-${tradeType}`;
    }
  }
  return '';
};

/**
 * Returns a class name when there are any buy trade rule to prioritize.
 * Used for targeting components while launching product tours
 * @param {object} tradeRules
 * @param {string} tradeType
 * @returns {string} - class name.
 */
export const prioritizeTradesClassName = (tradeRules, tradeType) => {
  if (tradeRules && tradeType) {
    const { buy } = tradeRules;
    if (buy?.length > 0) {
      return ` prioritize-trade-rules-${tradeType}`;
    }
  }
  return '';
};
