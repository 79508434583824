/**
 * @generated SignedSource<<b8caec174b2a667189a31a8e773b9246>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_CustomerPortfolioRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomerPortfolioLayout_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Portfolio",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomerPortfolioLayout_portfolio"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_CustomerPortfolioRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewerUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "givenName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "familyName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PortfolioConnection",
            "kind": "LinkedField",
            "name": "portfolios",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PortfolioEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Portfolio",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Portfolio",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed2839fac0381b42696e708c68026f10",
    "id": null,
    "metadata": {},
    "name": "routes_CustomerPortfolioRoot_Query",
    "operationKind": "query",
    "text": "query routes_CustomerPortfolioRoot_Query(\n  $id: ID!\n) {\n  viewer {\n    ...CustomerPortfolioLayout_viewer\n    id\n  }\n  portfolio(id: $id) {\n    ...CustomerPortfolioLayout_portfolio\n    id\n  }\n}\n\nfragment CustomerPortfolioLayout_portfolio on Portfolio {\n  title\n  ...CustomerPortfolioNavigation_portfolio\n}\n\nfragment CustomerPortfolioLayout_viewer on Viewer {\n  id\n  viewerUser {\n    id\n    type\n  }\n  ...CustomerPortfolioNavigation_viewer\n  portfolios {\n    ...CustomerPortfolioNavigation_portfolios\n  }\n}\n\nfragment CustomerPortfolioNavigation_portfolio on Portfolio {\n  id\n  title\n}\n\nfragment CustomerPortfolioNavigation_portfolios on PortfolioConnection {\n  edges {\n    node {\n      id\n      title\n    }\n  }\n}\n\nfragment CustomerPortfolioNavigation_viewer on Viewer {\n  id\n  viewerUser {\n    email\n    givenName\n    familyName\n    type\n    id\n  }\n}\n"
  }
};
})();

node.hash = "d55835cd4ca2ed2e560e833beba30e8c";

module.exports = node;
