import Big from 'big.js';
import { format } from 'd3-format';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';

import { APIConfig } from 'src/config';
import { COST_BENEFIT_STATE_ICONS, INHIBIT_CARBON_DATA_VIEWS, PLATFORM_MODE_REBATE } from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { costBenefitLabel, netValueDiffState } from 'src/util/counterfactual';
import { i18nDecimalFormat } from 'src/util/i18n/handler';

import { UNIT_CARBON_TOOLTIP_FORMAT } from './chartConstants';
import { formatCarbonEmission } from '../helpers/common';

const Div = styled.div`
  border-${(props) => props.align}:
    .25rem solid ${(props) => (props.category === 'imports' ? '#e6c644' : '#3bb667')};
    margin-${(props) => props.align}: .5rem;
    padding-${(props) => props.align}: 1rem;
    text-align: ${(props) => props.align};
`;

const Title = styled.h3.attrs({
  className: 'text-uppercase',
})`
  font-size: .8rem;
  color: #adb5bd;
`;

/** The styled component for cost benefit text. */
const CostBenefitText = styled.span.attrs({
  className: 'ms-2',
})`
font-size: 0.8rem;
`;

/**
 * Transforms a big number into a string carbon value with its unit label
 * @param {Big} carbonValue
 * @returns {string} - carbon data i.e 373.334kg CO2-e
 */
export const getCarbonData = (carbonValue) => {
  const finalValues = formatCarbonEmission(carbonValue);
  const { value: finalValue, label } = finalValues;
  return `${format(UNIT_CARBON_TOOLTIP_FORMAT)(finalValue)}${label}`;
};

const inhibitCarbonDataViews = APIConfig()?.feature(INHIBIT_CARBON_DATA_VIEWS);
/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - ChartSummary component
 */
function ChartSummary(props) {
  const {
    align, carbonData, category, counterfactual, intl, mode,
    rebatedEnergy, hasCounterfactual, tradedValue, tradedEnergy,
    untradedEnergy, untradedValue,
  } = props;

  const finalTradedEnergy = (format('.4s')(tradedEnergy === undefined ? 0 : tradedEnergy));
  const finalTradeValue = format('.2f')(tradedValue === undefined ? 0 : tradedValue);
  const netValueDiff = tradedValue - counterfactual;

  const direction = category === 'imports' ? 'TRADE_DIRECTION_BUY' : 'TRADE_DIRECTION_SELL';
  const absoluteValueDiff = Math.abs(netValueDiff);
  const diffState = netValueDiffState(netValueDiff, direction);
  const finalDiffState = diffState ? Object.keys(diffState).filter((k) => diffState[k])[0] : null;

  const title = category === 'imports' ? <FormattedMessage id="common.entities.energy_imports.label" defaultMessage="Energy Imports" />
    : <FormattedMessage id="common.entities.energy_exports.label" defaultMessage="Energy Exports" />;

  if (mode === PLATFORM_MODE_REBATE) {
    const finalRebatedEnergy = format('.4s')(rebatedEnergy === undefined ? 0 : rebatedEnergy);
    return (
      <Div category={category} align={align}>
        <Title>{title}</Title>
        <dl>
          <dt>
            <FormattedMessage id="chart.chart_summary.traded_volume.label" defaultMessage="Traded energy" />
          </dt>
          <dd>
            {i18nDecimalFormat(finalTradedEnergy)}
            <FormattedMessage id="common.units.energy_cost_wh.abbr.label" defaultMessage="Wh" />
          </dd>
          <dt>
            {' '}
            <FormattedMessage id="chart.chart_summary.discount.label" defaultMessage="Discounted energy" />
          </dt>
          <dd>
            {i18nDecimalFormat(finalRebatedEnergy)}
            <FormattedMessage id="common.units.energy_cost_wh.abbr.label" defaultMessage="Wh" />
          </dd>
          <dt>
            {' '}
            <FormattedMessage id="chart.chart_summary.discount_amount.label" defaultMessage="Discount amount" />
          </dt>
          <dd>
            <FormattedMessage id="common.currency.symbol" defaultMessage="{n, number, ::currency/AUD}" values={{ n: finalTradeValue }} />
          </dd>
          {untradedEnergy > 0 && (
            <>
              <dt>
                {' '}
                <FormattedMessage id="chart.chart_summary.untraded_volume.label" defaultMessage="Untraded energy" />
              </dt>
              <dd>
                {i18nDecimalFormat(format('.4s')(untradedEnergy))}
                <FormattedMessage id="common.units.energy_cost_wh.abbr.label" defaultMessage="Wh" />
              </dd>
              {hasCounterfactual && untradedValue > 0 && (
                <>
                  <dt>
                    <FormattedMessage id="chart.chart_summary.untraded_value.label" defaultMessage="Untraded value" />
                  </dt>
                  <dd>
                    <FormattedMessage id="common.currency.symbol" defaultMessage="{n, number, ::currency/AUD}" values={{ n: untradedValue }} />
                  </dd>
                </>
              )}
            </>
          )}
        </dl>
      </Div>
    );
  }

  let averagePrice = 0;
  if (tradedValue !== undefined && tradedEnergy !== undefined && tradedEnergy !== 0) {
    averagePrice = tradedValue / tradedEnergy;
  }

  return (
    <Div category={category} align={align} className={`summary-${category}`}>
      <Title>{title}</Title>
      <dl>
        <dt><FormattedMessage id="chart.chart_summary.average_price.label" defaultMessage="Average price" /></dt>
        <dd>
          {i18nDecimalFormat(format('.3f')(convertEnergyPrice(averagePrice)))}
          <FormattedMessage id="common.units.energy_cost.abbr.label" defaultMessage="c/kWh" />
        </dd>
        <dt><FormattedMessage id="chart.chart_summary.traded_value.label" defaultMessage="Traded value" /></dt>
        <dd>
          <FormattedMessage id="common.currency.symbol" defaultMessage="{n, number, ::currency/AUD}" values={{ n: finalTradeValue }} />
          {hasCounterfactual && (
            <CostBenefitText>
              <span className="me-1">{COST_BENEFIT_STATE_ICONS[finalDiffState]}</span>
              <FormattedMessage
                id="chart.chart_summary.platform_mode_trade.counterfactual.details"
                defaultMessage=" {costBenefitAbbr}"
                values={{ costBenefitAbbr: <abbr title={costBenefitLabel(intl, absoluteValueDiff, finalDiffState)}>{format('.2f')(absoluteValueDiff)}</abbr> }}
              />
            </CostBenefitText>
          )}
        </dd>
        <dt><FormattedMessage id="chart.chart_summary.traded_volume.label" defaultMessage="Traded energy" /></dt>
        <dd>
          {i18nDecimalFormat(finalTradedEnergy)}
          <FormattedMessage id="common.units.energy_cost_wh.abbr.label" defaultMessage="Wh" />
        </dd>
        {untradedEnergy > 0 && (
          <>
            {
              hasCounterfactual > 0 && (
                <>
                  <dt>
                    <FormattedMessage id="chart.chart_summary.untraded_value.label" defaultMessage="Untraded value" />
                  </dt>
                  <dd>
                    <FormattedMessage id="common.currency.symbol" defaultMessage="{n, number, ::currency/AUD}" values={{ n: untradedValue }} />
                  </dd>
                </>
              )
            }
            <dt><FormattedMessage id="chart.chart_summary.untraded_volume.label" defaultMessage="Untraded energy" /></dt>
            <dd>
              {i18nDecimalFormat(format('.4s')(untradedEnergy))}
              <FormattedMessage id="common.units.energy_cost_wh.abbr.label" defaultMessage="Wh" />
            </dd>

          </>
        )}
        {
          !inhibitCarbonDataViews && carbonData > 0 && (
            <>
              <dt><FormattedMessage id="chart.chart_summary.carbon.label" defaultMessage="Carbon" /></dt>
              <dd>
                {i18nDecimalFormat(getCarbonData(carbonData))}
              </dd>
            </>
          )
        }
      </dl>
    </Div>
  );
}

ChartSummary.propTypes = {
  category: PropTypes.oneOf(['imports', 'exports']).isRequired,
  hasCounterfactual: PropTypes.bool,
  tradedValue: PropTypes.number.isRequired,
  counterfactual: PropTypes.number.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  tradedEnergy: PropTypes.number.isRequired,
  rebatedEnergy: PropTypes.number,
  align: PropTypes.oneOf(['left', 'right']),
  mode: PropTypes.string.isRequired,
  carbonData: PropTypes.oneOfType([
    PropTypes.instanceOf(Big),
    PropTypes.number,
  ]),
  untradedEnergy: PropTypes.number.isRequired,
  untradedValue: PropTypes.number.isRequired,
};

ChartSummary.defaultProps = {
  align: 'left',
  hasCounterfactual: false,
  rebatedEnergy: 0,
  carbonData: 0,
};

export default injectIntl(ChartSummary);
