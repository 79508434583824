import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Card, CardHeader, Col, Row, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

import AdminPrimaryBillingPointShowMeter from './AdminPrimaryBillingPointShowMeter';
// import AdminPrimaryBillingPointShowTariff from './AdminPrimaryBillingPointShowTariff';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminPrimaryBillingPoint component
 */
function AdminPrimaryBillingPoint(props) {
  if (!props) {
    return <Loading />;
  }

  const { primaryBillingPoint } = props;
  const {
    id, externalIdentifier, active, identifier, property, createdAt, updatedAt, meters,
  } = primaryBillingPoint;
  const { start, finish } = active;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Enosi Admin - Primary Billing Point - ${identifier}`}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Primary Billing Points', path: '/admin/primary-billing-points' },
          { name: identifier },
        ]}
      />

      <h1>
        {identifier}
      </h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
              Primary Billing Point details
            </CardHeader>
            <Table responsive>
              <tbody>
                <tr>
                  <th scope="row" className="text-right">ID</th>
                  <td>
                    <UUID uuid={id} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">External identifier</th>
                  <td>{externalIdentifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Active status</th>
                  <td>
                    <BadgeActive start={start} finish={finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Start</th>
                  <td>
                    {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Finish</th>
                  <td>
                    {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Identifier</th>
                  <td>{identifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Property</th>
                  <td>
                    <Link to={`/admin/properties/${property.id}`}>
                      <strong>{property && property.title}</strong>
                    </Link>
                    <br />
                    <UUID uuid={property && property.id} />
                    <br />
                    <BadgeActive start={property.active.start} finish={property.active.finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Created at</th>
                  <td>
                    {createdAt
                      && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Updated at</th>
                  <td>
                    {updatedAt
                      && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={8}>
          <Card className="mb-4">
            <CardHeader>
              Meters
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Identifier</th>
                  <th scope="col">
                    <abbr title="Identifier">ID</abbr>
                  </th>
                  <th scope="col">Active</th>
                  <th scope="col">Primary Billing Point</th>
                </tr>
              </thead>
              <tbody>
                {meters && meters.edges.map((el) => (
                  <AdminPrimaryBillingPointShowMeter meter={el.node} key={el.node.id} />
                ))}
              </tbody>
            </Table>
          </Card>

          {/* <Card className="mb-4">
            <CardHeader>
              Tariffs
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Identifier</th>
                  <th scope="col">
                    <abbr title="Identifier">ID</abbr>
                  </th>
                  <th scope="col">Active</th>
                  <th scope="col">Meter</th>
                </tr>
              </thead>
              <tbody>
                {tariffs && tariffs.edges.map((el) => (
                  <AdminPrimaryBillingPointShowTariff tariff={el.node} key={el.node.id} />
                ))}
              </tbody>
            </Table>
          </Card> */}
        </Col>
      </Row>
    </>
  );
}

AdminPrimaryBillingPoint.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  primaryBillingPoint: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminPrimaryBillingPoint.defaultProps = {
  primaryBillingPoint: null,
};

export default createFragmentContainer(
  AdminPrimaryBillingPoint,
  {
    primaryBillingPoint: graphql`
      fragment AdminPrimaryBillingPointShow_primaryBillingPoint on PrimaryBillingPoint {
        id
        externalIdentifier
        active { start finish }
        identifier
        property {
          id
          title
          active {
            start
            finish
          }
        }
        createdAt
        updatedAt
        meters(first: 500) {
          edges {
            node {
              id
              identifier
              active { start finish }
              primaryBillingPoint {
                id
                identifier
              }
            }
          }
        }
      }
    `,
  },
);
