import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Card, CardHeader, Col, Row, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

import AdminTariffShowVolumeCharge from './AdminTariffShowVolumeCharge';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminTariffShow component
 */
function AdminTariffShow(props) {
  if (!props) {
    return <Loading />;
  }

  const { tariff } = props;
  const {
    id, identifier, externalIdentifier, title,
    tariffType, active, createdAt, updatedAt,
    meter, property, volumeCharges,
  } = tariff;
  const { start, finish } = active;
  const {
    id: meterId, identifier: meterIdentifier, active: meterActive,
  } = meter;
  const {
    id: propertyId, title: propertyTitle, active: propertyActive,
  } = property;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Enosi Admin - Tariff - ${title}`}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Tariffs', path: '/admin/tariffs' },
          { name: title },
        ]}
      />

      <h1>
        {title}
      </h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
              Tariff details
            </CardHeader>
            <Table responsive>
              <tbody>
                <tr>
                  <th scope="row" className="text-right">Active status</th>
                  <td>
                    <BadgeActive start={start} finish={finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">ID</th>
                  <td>
                    <UUID uuid={id} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <td>{externalIdentifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Start</th>
                  <td>
                    {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Finish</th>
                  <td>
                    {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Title</th>
                  <td>{title}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Tariff type</th>
                  <td>{tariffType}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Identifier</th>
                  <td>{identifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Meter</th>
                  <td>
                    <Link to={`/admin/meters/${meterId}`}>
                      <strong>{meterIdentifier}</strong>
                    </Link>
                    <br />
                    <UUID uuid={meterId} />
                    <br />
                    <BadgeActive start={meterActive.start} finish={meterActive.finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Property</th>
                  <td>
                    <Link to={`/admin/properties/${propertyId}`}>
                      <strong>{propertyTitle}</strong>
                    </Link>
                    <br />
                    <UUID uuid={propertyId} />
                    <br />
                    <BadgeActive start={propertyActive.start} finish={propertyActive.finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Created at</th>
                  <td>
                    {createdAt
                      && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Updated at</th>
                  <td>
                    {updatedAt
                      && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={8}>
          <Card>
            <CardHeader>
              Volume charges
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Identifier</th>
                  <th scope="col">ID</th>
                  <th scope="col">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <th scope="col">Rate</th>
                  <th scope="col">Flags</th>
                </tr>
              </thead>
              <tbody>
                {volumeCharges && volumeCharges.edges.map((el) => (
                  <AdminTariffShowVolumeCharge volumeCharge={el.node} key={el.node.id} />
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}

AdminTariffShow.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  tariff: PropTypes.shape({
    id: PropTypes.string,
    identifier: PropTypes.string,
    externalIdentifier: PropTypes.string,
    title: PropTypes.string,
    tariffType: PropTypes.string,
    createdAt: PropTypes.number,
    updatedAt: PropTypes.number,
    active: PropTypes.shape({
      start: PropTypes.number,
      finish: PropTypes.number,
    }),
    meter: PropTypes.shape({
      id: PropTypes.string,
      identifier: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
    }),
    property: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
      address: PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postcode: PropTypes.string,
      }),
    }),
    volumeCharges: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            identifier: PropTypes.string,
            externalIdentifier: PropTypes.string,
            title: PropTypes.string,
            rate: PropTypes.number,
            importFlag: PropTypes.bool,
            exportFlag: PropTypes.bool,
            ignorePublicHolidays: PropTypes.bool,
          }),
        }),
      ),
    }),
  }),
};

AdminTariffShow.defaultProps = {
  tariff: null,
};

export default createFragmentContainer(
  AdminTariffShow,
  {
    tariff: graphql`
      fragment AdminTariffShow_tariff on Tariff {
        id
        identifier
        externalIdentifier
        title
        tariffType
        createdAt
        updatedAt
        active { start finish }
        meter {
          id
          identifier
          active { start finish }
        }
        property {
          id
          title
          active { start finish }
        }
        volumeCharges(first: 500) {
          edges {
            node {
              id
              identifier
              externalIdentifier
              title
              rate
              importFlag
              exportFlag
              ignorePublicHolidays
            }
          }
        }
      }
    `,
  },
);
