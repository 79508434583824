import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { Button } from 'reactstrap';

import AcceptNominatedTradeMutation from 'src/mutations/AcceptNominatedTradeMutation';
import CancelNominatedTradeMutation from 'src/mutations/CancelNominatedTradeMutation';
import CloseNominatedTradeMutation from 'src/mutations/CloseNominatedTradeMutation';
import RejectNominatedTradeMutation from 'src/mutations/RejectNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import {
  TRADE_RULE_ACCEPT, TRADE_RULE_CANCEL, TRADE_RULE_CLOSE, TRADE_RULE_REJECT,
  TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CANCELLED, TRADE_RULE_STATE_CLOSED,
  TRADE_RULE_STATE_PROPOSED, TRADE_RULE_STATE_REJECTED,
  TRADE_TYPE_CONTRACTED, TRADE_TYPE_NOMINATED, TRADE_TYPE_RESIDUAL,
} from 'src/util/constants';

import TradeRuleTimelineStepAccepted from './TradeRuleTimelineStepAccepted';
import TradeRuleTimelineStepCancelled from './TradeRuleTimelineStepCancelled';
import TradeRuleTimelineStepClosed from './TradeRuleTimelineStepClosed';
import TradeRuleTimelineStepProposed from './TradeRuleTimelineStepProposed';
import TradeRuleTimelineStepRejected from './TradeRuleTimelineStepRejected';
import { getLastStep, tradeRuleActions } from '../TradeRuleHelpers';

class TradeRuleTimeline extends React.Component {
  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleNominatedTradeMutationFailure = (error) => {
    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleCancelNominatedTradeMutationSuccess = (response, formatMessage) => {
    const { id } = response.cancelNominatedTrade;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_cancelled.response_message.success', defaultMessage: 'Cancelled peer-to-peer trade rule proposal with id: {id}.' }, id),
    );
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleRejectNominatedTradeMutationSuccess = (response, formatMessage) => {
    const { id } = response.rejectNominatedTrade;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_rejected.response_message.success', defaultMessage: 'Rejected peer-to-peer trade rule proposal with id: {id}.' }, id),
    );
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleAcceptNominatedTradeMutationSuccess = (response, formatMessage) => {
    const { id } = response.acceptNominatedTrade;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_accepted.response_message.success', defaultMessage: 'Accepted peer-to-peer trade rule proposal with id: {id}.' }, id),
    );
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleCloseNominatedTradeMutationSuccess = (response, formatMessage) => {
    const { id } = response.closeNominatedTrade;
    FlashesStore.flash(
      FlashesStore.SUCCESS,
      formatMessage({ id: 'trade_rule.trade_rule_timeline.trade_rule_closed.response_message.success', defaultMessage: 'Closed peer-to-peer trade rule with id: {id}.' }, id),
    );
  };

  tradeRuleAction = (rule, action, formatMessage, opts = {}) => {
    const { id } = rule;
    switch (action) {
      case TRADE_RULE_CANCEL:
        CancelNominatedTradeMutation(
          id,
          this.handleCancelNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
          formatMessage,
        );
        break;
      case TRADE_RULE_REJECT:
        RejectNominatedTradeMutation(
          id,
          this.handleRejectNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
          formatMessage,
        );
        break;
      case TRADE_RULE_ACCEPT:
        AcceptNominatedTradeMutation(
          id,
          opts.tradePointId,
          this.handleAcceptNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
          formatMessage,
        );
        break;
      case TRADE_RULE_CLOSE:
        CloseNominatedTradeMutation(
          id,
          this.handleCloseNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
          formatMessage,
        );
        return;
      default:
        FlashesStore.flash(
          FlashesStore.ERROR,
          formatMessage({ id: 'trade_rule.trade_rule_timeline.response_message.error', defaultMessage: 'Action {action} is not possible.' }, { action }),
        );
    }
  };

  tradeRuleActionButton = (rule, metersMap, last, formatMessage) => {
    const {
      tradeType, buyer, seller, proposedBy,
    } = rule;

    const trader = (buyer.tradePoint.id in metersMap) ? buyer : seller;
    let buttons;
    if (tradeType === TRADE_TYPE_NOMINATED) {
      if (last === TRADE_RULE_STATE_PROPOSED) {
        if (proposedBy.id === trader.user.id) {
          buttons = (
            <Button color="primary" onClick={() => this.tradeRuleAction(rule, TRADE_RULE_CANCEL, formatMessage)}>
              <FormattedMessage id="trade_rule.trade_rule_timeline.cancel_trade.label" defaultMessage="Cancel trade rule" />
            </Button>
          );
        } else {
          buttons = (
            <>
              <Button
                color="primary"
                onClick={() => this.tradeRuleAction(rule, TRADE_RULE_ACCEPT, formatMessage, {
                  tradePointId: trader.tradePoint.id,
                })}
              >
                <FormattedMessage id="trade_rule.trade_rule_timeline.accept_trade.label" defaultMessage="Accept trade rule" />
              </Button>
              <Button color="primary" onClick={() => this.tradeRuleAction(rule, TRADE_RULE_REJECT, formatMessage)}>
                <FormattedMessage id="trade_rule.trade_rule_timeline.reject_trade.label" defaultMessage="Reject trade rule" />
              </Button>
            </>
          );
        }
      } else if (last === TRADE_RULE_STATE_ACCEPTED) {
        buttons = (
          <Button color="primary" onClick={() => this.tradeRuleAction(rule, TRADE_RULE_CLOSE, formatMessage)}>
            <FormattedMessage id="trade_rule.trade_rule_timeline.close_trade.label" defaultMessage="Close trade rule" />
          </Button>
        );
      }
    } else if (tradeType === TRADE_TYPE_CONTRACTED || tradeType === TRADE_TYPE_RESIDUAL) {
      if (last === TRADE_RULE_STATE_PROPOSED || last === TRADE_RULE_STATE_ACCEPTED) {
        buttons = <FormattedMessage id="trade_rule.trade_rule_timeline.no_actions.label" defaultMessage="'No actions available'" />;
      }
    }

    return tradeRuleActions(buttons);
  };

  render() {
    const { intl, property, rule } = this.props;
    const { formatMessage } = intl;

    // Build a map of tradePointId => Meter for display in the trade rule timeline event
    const { meters, timezone } = property;
    const { edges: metersEdges } = meters;
    const metersMap = {};
    metersEdges.forEach(({ node }) => {
      metersMap[node.tradePointId] = node;
    });

    const { title } = property;

    // Extract the trade rule information required for each event
    const {
      id, tradeType, clauses, buyer, seller, proposedAt, proposedBy, acceptedAt, acceptedBy,
      rejectedAt, rejectedBy, cancelledAt, cancelledBy, closedAt, closedBy,
    } = rule;

    const last = getLastStep(rule);

    const buttons = this.tradeRuleActionButton(rule, metersMap, last, formatMessage);

    return (
      <ol className="m-0 p-0">
        <TradeRuleTimelineStepProposed
          propertyTitle={title}
          timezone={timezone}
          ruleId={id}
          tradeType={tradeType}
          clauses={clauses}
          buyer={buyer}
          seller={seller}
          timestamp={proposedAt}
          user={proposedBy}
          isCurrentStep={last === TRADE_RULE_STATE_PROPOSED}
          hasNextStep={last !== TRADE_RULE_STATE_PROPOSED || !!buttons}
        />
        {acceptedAt && (
          <TradeRuleTimelineStepAccepted
            metersMap={metersMap}
            propertyTitle={title}
            timezone={timezone}
            ruleId={id}
            tradeType={tradeType}
            clauses={clauses}
            buyer={buyer}
            seller={seller}
            timestamp={acceptedAt}
            user={acceptedBy}
            isCurrentStep={last === TRADE_RULE_STATE_ACCEPTED}
            hasNextStep={last !== TRADE_RULE_STATE_ACCEPTED || !!buttons}
          />
        )}
        {closedAt && (
          <TradeRuleTimelineStepClosed
            metersMap={metersMap}
            propertyTitle={title}
            timezone={timezone}
            ruleId={id}
            tradeType={tradeType}
            buyer={buyer}
            seller={seller}
            timestamp={closedAt}
            user={closedBy}
            isCurrentStep={last === TRADE_RULE_STATE_CLOSED}
            hasNextStep={last !== TRADE_RULE_STATE_CLOSED || !!buttons}
          />
        )}
        {cancelledAt && (
          <TradeRuleTimelineStepCancelled
            metersMap={metersMap}
            timezone={timezone}
            ruleId={id}
            tradeType={tradeType}
            timestamp={cancelledAt}
            user={cancelledBy}
            isCurrentStep={last === TRADE_RULE_STATE_CANCELLED}
            hasNextStep={last !== TRADE_RULE_STATE_CANCELLED || !!buttons}
          />
        )}
        {rejectedAt && (
          <TradeRuleTimelineStepRejected
            metersMap={metersMap}
            timezone={timezone}
            ruleId={id}
            tradeType={tradeType}
            timestamp={rejectedAt}
            user={rejectedBy}
            isCurrentStep={last === TRADE_RULE_STATE_REJECTED}
            hasNextStep={last !== TRADE_RULE_STATE_REJECTED || !!buttons}
          />
        )}
        {buttons}
      </ol>
    );
  }
}

TradeRuleTimeline.propTypes = {
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  rule: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default injectIntl(createFragmentContainer(
  TradeRuleTimeline,
  {
    property: graphql`
      fragment TradeRuleTimeline_property on Property {
      id
      title
      timezone
        address {
        line1
        line2
        city
        state
        postcode
          gps {
          latitude
          longitude
        }
      }
        meters {
          edges {
            node {
            id
            identifier
            title
            tradePointId
          }
        }
      }
    }
    `,
    rule: graphql`
      fragment TradeRuleTimeline_rule on TradeRule {
      id
      tradeType
        buyer {
          user { id email givenName familyName }
          community { id title }
        residualId
          tradePoint {
          id
          type
            meter {
            id
            identifier
            title
              property {
              id
              title
            }
          }
        }
      }
        seller {
          user { id email givenName familyName }
          community { id title }
        residualId
          tradePoint {
          id
          type
            meter {
            id
            identifier
            title
              property {
              id
              title
            }
          }
        }
      }
        clauses {
          edges {
            node {
            monthsOfYear
            daysOfWeek
              timesOfDay {
                start  { hours minutes seconds }
                finish { hours minutes seconds }
            }
            ignoreDaylightSavings
            ignorePublicHolidays
            price
            timezone
          }
        }
      }
      proposedAt
        proposedBy { id email givenName familyName }
      acceptedAt
        acceptedBy { id email givenName familyName }
      rejectedAt
        rejectedBy { id email givenName familyName }
      cancelledAt
        cancelledBy { id email givenName familyName }
      closedAt
        closedBy { id email givenName familyName }
    }
    `,
  },
));
