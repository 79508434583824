import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Card, CardHeader, Col, Row, Table,
} from 'reactstrap';

import Address from 'src/components/Address';
import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

import AdminPropertyShowMeter from './AdminPropertyShowMeter';
import AdminPropertyShowPrimaryBillingPoint from './AdminPropertyShowPrimaryBillingPoint';
import AdminPropertyShowPropertyUser from './AdminPropertyShowPropertyUser';
import AdminPropertyShowTariff from './AdminPropertyShowTariff';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminPropertyShow component
 */
function AdminPropertyShow(props) {
  if (!props) {
    return <Loading />;
  }

  const { property } = props;
  const {
    id, externalIdentifier, title, address, active, createdAt, updatedAt, propertyUsers,
    primaryBillingPoints, meters, tariffs,
  } = property;
  const { start, finish } = active;
  const addressString = new Address(address).string();
  const { gps } = address;
  const latitude = gps ? gps.latitude : '';
  const longitude = gps ? gps.longitude : '';

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Enosi Admin - Property - ${title}`}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Properties', path: '/admin/properties' },
          { name: title },
        ]}
      />

      <h1>
        {title}
      </h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
              Property details
            </CardHeader>
            <Table responsive>
              <tbody>
                <tr>
                  <th scope="row" className="text-right">Links</th>
                  <td>
                    <Link to={`/properties/${id}`}>
                      Customer Page
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">ID</th>
                  <td>
                    <UUID uuid={id} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">External identifier</th>
                  <td>{externalIdentifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Active status</th>
                  <td>
                    <BadgeActive start={start} finish={finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Title</th>
                  <td>{title}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Address</th>
                  <td>{addressString}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Latitude</th>
                  <td>{latitude}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Longitude</th>
                  <td>{longitude}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Start</th>
                  <td>
                    {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Finish</th>
                  <td>
                    {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Created at</th>
                  <td>
                    {createdAt
                      && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Updated at</th>
                  <td>
                    {updatedAt
                      && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={8}>
          <Card className="mb-4">
            <CardHeader>
              Property Users
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col" colSpan={3} className="text-center">PropertyUser</th>
                  <th scope="col" colSpan={5} className="text-center">User</th>
                </tr>
                <tr>
                  <th scope="col">
                    <abbr title="Identifier">ID</abbr>
                  </th>
                  <th scope="col">Active</th>
                  <th scope="col">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <th scope="col">Username</th>
                  <th scope="col">Email</th>
                  <th scope="col">Active</th>
                  <th scope="col">
                    <abbr title="Identifier">ID</abbr>
                  </th>
                  <th scope="col">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                </tr>
              </thead>
              <tbody>
                {propertyUsers && propertyUsers.edges.map((el) => (
                  <AdminPropertyShowPropertyUser propertyUser={el.node} key={el.node.id} />
                ))}
              </tbody>
            </Table>
          </Card>

          <Card className="mb-4">
            <CardHeader>
              Primary Billing Points
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Identifier</th>
                  <th scope="col">
                    <abbr title="Identifier">ID</abbr>
                  </th>
                  <th scope="col">Active</th>
                </tr>
              </thead>
              <tbody>
                {primaryBillingPoints && primaryBillingPoints.edges.map((el) => (
                  <AdminPropertyShowPrimaryBillingPoint
                    primaryBillingPoint={el.node}
                    key={el.node.id}
                  />
                ))}
              </tbody>
            </Table>
          </Card>

          <Card className="mb-4">
            <CardHeader>
              Meters
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Identifier</th>
                  <th scope="col">
                    <abbr title="Identifier">ID</abbr>
                  </th>
                  <th scope="col">Active</th>
                  <th scope="col">Primary Billing Point</th>
                </tr>
              </thead>
              <tbody>
                {meters && meters.edges.map((el) => (
                  <AdminPropertyShowMeter meter={el.node} key={el.node.id} />
                ))}
              </tbody>
            </Table>
          </Card>

          <Card className="mb-4">
            <CardHeader>
              Tariffs
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Identifier</th>
                  <th scope="col">
                    <abbr title="Identifier">ID</abbr>
                  </th>
                  <th scope="col">Active</th>
                  <th scope="col">Meter</th>
                </tr>
              </thead>
              <tbody>
                {tariffs && tariffs.edges.map((el) => (
                  <AdminPropertyShowTariff tariff={el.node} key={el.node.id} />
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </>
  );
}

AdminPropertyShow.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminPropertyShow.defaultProps = {
  property: null,
};

export default createFragmentContainer(
  AdminPropertyShow,
  {
    property: graphql`
      fragment AdminPropertyShow_property on Property {
        id
        externalIdentifier
        title
        address {
          line1
          line2
          city
          state
          postcode
          country
          gps {
            latitude
            longitude
          }
        }
        active { start finish }
        createdAt
        updatedAt
        propertyUsers(first: 500) {
          edges {
            node {
              id
              externalIdentifier
              active { start finish }
              user {
                id
                externalIdentifier
                email
                givenName
                familyName
                active { start finish }
              }
            }
          }
        }
        primaryBillingPoints(first: 500) {
          edges {
            node {
              id
              identifier
              active { start finish }
            }
          }
        }
        meters(first: 500) {
          edges {
            node {
              id
              identifier
              active { start finish }
              primaryBillingPoint {
                id
                identifier
              }
            }
          }
        }
        tariffs(first: 500) {
          edges {
            node {
              id
              identifier
              title
              active { start finish }
              meter {
                id
                identifier
              }
            }
          }
        }
      }
    `,
  },
);
