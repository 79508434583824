import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';
import { Button } from 'reactstrap';

import { FilterBar } from 'src/components/Filter';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import {
  ACTIVE_STATE_ACTIVE, ACTIVE_STATE_INACTIVE, LIST_FILTER_ALL,
} from 'src/util/constants';

import { PortfolioListCards } from './PortfolioListCards';
import { hasPortfolio, portfoliosWithActiveState } from './helpers';

const PortfolioListFragment = graphql`
fragment PortfolioList_viewer on Viewer
@refetchable(queryName: "PortfolioListRefetchQuery")
@argumentDefinitions (
    cursor: { type: "String" }
    count: { type: "Int"}
  )
{
  id
  portfolios(first: $count, after: $cursor)
    @connection(key: "PortfoliosList_portfolios") {
      edges {
        cursor
        node {
          id
          title
          active {
            start
            finish
          }
          propertyMembers(first: 500) {
            count
            edges {
              node {
                id
              }
            }
          }
          userMembers(first: 500) {
            count
            edges {
              node {
                id
              }
            }
          }
        }
      }
      count
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
}
`;

/**
 * @param {object} param
 * @param {object} param.viewer - viewer object
 * @returns {React.ReactComponentElement} - PortfolioList component
 */
function PortfolioList({ viewer }) {
  if (!viewer) {
    return <Loading />;
  }

  const {
    data, loadNext, hasNext, isLoadingNext,
  } = usePaginationFragment(
    PortfolioListFragment,
    viewer,
  );

  const intl = useIntl();
  const { formatMessage } = intl;

  const { portfolios } = data || {};

  const pageTitle = formatMessage({ id: 'portfolio.portfolio_list.page.title', defaultMessage: 'Enosi - your portfolios' });

  const [activeState, setActiveState] = useState(ACTIVE_STATE_ACTIVE);

  /**
   * Generates an array of filter bar buttons for the portfolio list. All | Active | Inactive
   * @returns {Array<object>} An array of filter bar button objects.
   */
  const filterBarButtons = () => [
    {
      group: LIST_FILTER_ALL,
      items: [
        { value: LIST_FILTER_ALL, label: <FormattedMessage id="portfolio.portfolio_list.filter.all.label" defaultMessage="All" />, active: activeState === LIST_FILTER_ALL },
      ],
    },
    {
      group: 'active',
      items: [
        { value: ACTIVE_STATE_ACTIVE, label: <FormattedMessage id="portfolio.portfolio_list.filter.active_status.active.label" defaultMessage="Active" />, active: activeState === ACTIVE_STATE_ACTIVE },
        { value: ACTIVE_STATE_INACTIVE, label: <FormattedMessage id="portfolio.portfolio_list.filter.active_status.inactive.label" defaultMessage="Inactive" />, active: activeState === ACTIVE_STATE_INACTIVE },
      ],
    },
  ];

  /**
   * Updates the filter state based on the provided group and value.
   * @param {object} param0 - An object containing the group and value to update the filter with.
   * @param {string} param0.group - The group to update the filter for.
   * @param {string} param0.value - The value to update the filter to.
   * @returns {void}
   */
  const updateFilter = ({ group, value }) => {
    if (value === LIST_FILTER_ALL) {
      setActiveState(LIST_FILTER_ALL);
    }
    if (group === 'active') {
      setActiveState(value);
    }
  };

  /**
   * Loads the next page of data for the portfolio list.
   * @returns {void}
   */
  const loadMore = () => {
    if (!hasNext || isLoadingNext) {
      return;
    }

    loadNext(
      50,
      { onComplete: (error) => { console.log({ error }); } },
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
      <Breadcrumbs breadcrumbs={[{ name: <FormattedMessage id="portfolio.portfolio_list.breadcrumbs.portfolios.title" defaultMessage="Portfolios" /> }]} />
      <FilterBar buttons={filterBarButtons()} onButtonClick={(e) => updateFilter(e)} />
      <div className={!hasPortfolio(portfolios) || portfoliosWithActiveState(portfolios, activeState).length === 0 ? 'mt-2 mb-4' : 'row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 mt-2'}>
        <PortfolioListCards portfolios={portfolios} activeState={activeState} />
      </div>
      <div>
        <Button onClick={loadMore} disabled={!hasNext}>
          <FormattedMessage id="portfolio.portfolio_list.button.pagination.load_more.label" defaultMessage="Load more..." />
        </Button>
      </div>
    </>
  );
}

export default PortfolioList;

PortfolioList.propTypes = {
  relay: PropTypes.shape({
    hasNext: PropTypes.func,
    isLoadingNext: PropTypes.func,
    loadNext: PropTypes.func,
  }),
  viewer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

PortfolioList.defaultProps = {
  relay: undefined,
};
