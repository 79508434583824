import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Button,
} from 'reactstrap';

import Loading from 'src/components/Loading';
import { APIConfig } from 'src/config';
import { LOAD_MORE, PLATFORM_MODE_REBATE, REFETCH } from 'src/util/constants';

import AdminMeterTradeRulesItem from './AdminMeterTradeRulesItem';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} -AdminMeterTradeRulesTable component
 */
function AdminMeterTradeRulesTable(props) {
  if (!props) {
    return <Loading />;
  }

  const {
    meter, rules, moreFunc, hasMore, updating,
  } = props;
  const { tradePointId, property } = meter;
  const { id: propertyId } = property;

  const trades = (!rules || !rules.edges)
    ? [] : rules.edges.map((el) => (el.node));

  const priceLabel = (
    <>
      {APIConfig().MODE === PLATFORM_MODE_REBATE ? 'Discount' : 'Price'}
      {' '}
      (
      <abbr title="cents per kilowatt hour">c/kWh</abbr>
      )
    </>
  );

  return (
    <div className="mt-4 mb-4 card">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">
                <abbr title="identifier">ID</abbr>
                {' '}
                / Direction / Type / State
              </th>
              <th scope="col">Counterparty Trader</th>
              <th scope="col">Counterparty Trade Point</th>
              <th scope="col">{priceLabel}</th>
              <th scope="col">Validity</th>
            </tr>
          </thead>
          <tbody>
            {updating !== REFETCH && trades
              && trades.map((tradeRule) => (
                <AdminMeterTradeRulesItem
                  tradePointId={tradePointId}
                  rule={tradeRule}
                  propertyId={propertyId}
                  key={tradeRule.id}
                />
              ))}
            {(updating === LOAD_MORE || updating === REFETCH) && (
              <tr>
                <td colSpan={5}>
                  <Loading />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="card-footer">
        <Button onClick={moreFunc} disabled={updating === LOAD_MORE || !hasMore}>
          Load more...
        </Button>
      </div>
    </div>
  );
}

AdminMeterTradeRulesTable.propTypes = {
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  rules: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  moreFunc: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  updating: PropTypes.string,
};

AdminMeterTradeRulesTable.defaultProps = {
  meter: null,
  rules: null,
  updating: null,
};

export default createFragmentContainer(
  AdminMeterTradeRulesTable,
  {
    rules: graphql`
      fragment AdminMeterTradeRulesTable_rules on TradeRuleConnection {
        edges {
          cursor
          node {
            id
            ...AdminMeterTradeRulesItem_rule
          }
        }
      }
    `,
  },
);
