import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import ProposeNominatedTradeMutation from 'src/mutations/ProposeNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';

import TradeRuleProposeNominatedTimeOfUseForm from './TradeRuleProposeNominatedTimeOfUseForm';

class TradeRuleProposeNominatedTimeOfUse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
    };
  }

  handleTradeRuleProposeNominated = (input) => {
    this.setState({ processing: true });
    FlashesStore.reset();

    ProposeNominatedTradeMutation(
      input,
      this.handleTradeRuleProposeNominatedSuccess,
      this.handleTradeRuleProposeNominatedFailure,
    );
  };

  handleTradeRuleProposeNominatedSuccess = (response, errors) => {
    const { intl } = this.props;
    this.setState({ processing: false });

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }));
      return;
    }

    const { id } = response.proposeNominatedTrade;
    const successMsg = intl.formatMessage({ id: 'trade_rule.trade_rule_propose_nominated_time_of_use.response_message.success', defaultMessage: 'A new peer-to-peer trade rule proposal has been sent with id {id}.' }, { id });

    FlashesStore.flash(FlashesStore.SUCCESS, successMsg);

    const { router, property } = this.props;
    router.push(`/properties/${property.id}/trade-rules/active`);
  };

  handleTradeRuleProposeNominatedFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  renderPropertyControls = () => {
    const { property } = this.props;
    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link
            to={`/properties/${property.id}/trade-rules`}
            className="btn btn-darken"
          >
            <FormattedMessage id="trade_rule.trade_rule_propose_nominated_time_of_use.all_trade_rules.label" defaultMessage="All trade rules" />
          </Link>
        </ButtonGroup>

        <ButtonGroup className="ms-2">
          <Link
            to={`/properties/${property.id}/settings`}
            className="btn btn-darken"
          >
            <FormattedMessage id="trade_rule.trade_rule_propose_nominated_time_of_use.property_settings.label" defaultMessage="Property Settings" />
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return <div><FormattedMessage id="error.title" defaultMessage="Error!" /></div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { intl, property, router } = this.props;
    const { address, id, title } = property;
    const addr = new Address(address);

    const { processing } = this.state;
    const pageTitle = intl.formatMessage({ id: 'trade_rule.trade_rule_propose_nominated_time_of_use.page.title', defaultMessage: 'Enosi - Property: {title} - Propose peer-to-peer trade rule' }, { title });

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            {
              name: <FormattedMessage id="trade_rule.trade_rule_propose_nominated_time_of_use.breadcrumbs.properties.label" defaultMessage=" Properties" />, path: '/properties',
            },
            { name: title, path: `/properties/${id}` },
            { name: <FormattedMessage id="trade_rule.trade_rule_propose_nominated_time_of_use.breadcrumbs.trade_rules.label" defaultMessage="Trade Rules" />, path: `/properties/${id}/trade-rules/active` },
            { name: intl.formatMessage({ id: 'trade_rule.trade_rule_propose_nominated_time_of_use.breadcrumbs.propose_nominated.title', defaultMessage: 'Propose peer-to-peer trade rule' }) },
          ]}
        />
        <Heading
          alias={title}
          address={addr.simpleString()}
          context={<FormattedMessage id="trade_rule.trade_rule_propose_nominated_time_of_use.breadcrumbs.heading.trade_rule_propose_nominated_flat.label" defaultMessage="Trade Rule - Propose Peer-to-Peer" />}
          controls={this.renderPropertyControls()}
        />
        <TradeRuleProposeNominatedTimeOfUseForm
          property={property}
          handleTradeRuleProposeNominated={this.handleTradeRuleProposeNominated}
          processing={processing}
          router={router}
        />
      </>
    );
  }
}

TradeRuleProposeNominatedTimeOfUse.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  intl: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleProposeNominatedTimeOfUse.defaultProps = {
  property: null,
};

export default injectIntl(createFragmentContainer(
  TradeRuleProposeNominatedTimeOfUse,
  {
    property: graphql`
      fragment TradeRuleProposeNominatedTimeOfUse_property on Property {
        id
        title
        address {
          line1
          line2
          city
          state
          postcode
          gps {
            latitude
            longitude
          }
        }
        ...TradeRuleProposeNominatedTimeOfUseForm_property
      }
    `,
  },
));
