import path from 'path';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import subHeading from 'src/components/Portfolio/helpers/common';
import Alert from 'src/enosikit/components/Alert';
import ListCard from 'src/enosikit/components/List/ListCard';
import { ACTIVE_STATE_ACTIVE, ACTIVE_STATE_INACTIVE, LIST_FILTER_ALL } from 'src/util/constants';

import { hasPortfolio, portfoliosWithActiveState } from './helpers';

/**
 * Renders an alert component to inform the user that no portfolios have been configured.
 * @returns {React.ReactElement} The alert component.
 */
export function NoPortfolioConfiguredAlert() {
  return (
    <Alert color="warning" className="mb-2 w-100">
      <FormattedMessage
        id="portfolio.portfolio_list.no_portfolio_configured.alert"
        defaultMessage="<strong>No portfolios configured.</strong> Unfortunately we do not currently have any portfolios configured for you.
           If you believe that this is an error, please get in touch via our help desk."
        values={{
          strong: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    </Alert>
  );
}

/**
 * Renders an alert component to notify the user that no active portfolio has been configured.
 * @returns {React.ReactElement} The alert component.
 */
export function NoActivePortfolioConfiguredAlert() {
  return (
    <Alert color="warning" className="mb-2 w-100">
      <FormattedMessage
        id="portfolio.portfolio_list.no_active_portfolios_configured.alert"
        defaultMessage="<strong>No active portfolios configured.</strong> Unfortunately we do not currently have any active portfolios configured for you, however there may be inactive portfolios available."
        values={{
          strong: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    </Alert>

  );
}

/**
 * Renders an alert component to display a message when there are no inactive portfolios configured.
 * @returns {React.ReactElement} The alert component.
 */
export function NoInactivePortfolioConfiguredAlert() {
  return (
    <Alert color="warning" className="mb-2 w-100">
      <FormattedMessage
        id="portfolio.portfolio_list.no_inactive_portfolios_configured.alert"
        defaultMessage="<strong>No inactive portfolios configured.</strong> Unfortunately we do not currently have any inactive portfolios configured for you, however there may be active portfolios available."
        values={{
          strong: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    </Alert>
  );
}

/**
 * Renders a list of portfolio cards.
 * @param {object} props the component props.
 * @param {object} props.portfolios portfolio connection {edges, pageInfo}.
 * @param {ACTIVE_STATE_ACTIVE | ACTIVE_STATE_INACTIVE | LIST_FILTER_ALL } props.activeState
 *  Whether a portfolio's active field (a timerange of `{start, finish}`) is currently valid as
 *  active, or not.
 * @returns {Array<React.ReactComponentElement> | React.ReactComponentElement | null}
 *  A list of portfolio cards or an alert.
 */
export function PortfolioListCards(props) {
  const { portfolios, activeState } = props;

  if (!hasPortfolio(portfolios)) return <NoPortfolioConfiguredAlert />;

  const portfoliosWithState = portfoliosWithActiveState(portfolios, activeState);

  if (portfoliosWithState.length === 0) {
    switch (activeState) {
      case ACTIVE_STATE_INACTIVE:
        return <NoInactivePortfolioConfiguredAlert />;

      case ACTIVE_STATE_ACTIVE:
        return <NoActivePortfolioConfiguredAlert />;

      default: return null;
    }
  }

  const intl = useIntl();
  const label = intl.formatMessage({ id: 'list.card.manage_portfolio', defaultMessage: 'Manage Portfolio' });

  return portfoliosWithState.map((edge) => {
    const { node } = edge;
    const {
      id, propertyMembers, title, userMembers,
    } = node;

    const { edges: propertyEdges } = propertyMembers;
    const propertyMemberCount = propertyEdges?.length || 0;

    const { edges: userEdges } = userMembers;
    const userMemberCount = userEdges?.length || 0;

    return (

      <div className="col mb-4" key={id}>
        <ListCard
          alias={title}
          label={label}
          path={path.join('/', 'portfolios', id)}
          subheading={subHeading(propertyMemberCount, userMemberCount)}
        />
      </div>
    );
  });
}

PortfolioListCards.propTypes = {
  activeState: PropTypes.oneOf([
    ACTIVE_STATE_ACTIVE, ACTIVE_STATE_INACTIVE, LIST_FILTER_ALL,
  ]).isRequired,
  portfolios: PropTypes.shape({
    count: PropTypes.number,
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        cursor: PropTypes.string,
        node: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          active: PropTypes.shape({
            start: PropTypes.number,
            finish: PropTypes.number,
          }),
          propertyMembers: PropTypes.shape({
            count: PropTypes.number,
            edges: PropTypes.arrayOf(
              PropTypes.shape({
                node: PropTypes.shape({
                  id: PropTypes.string,
                }),
              }),
            ),
          }),
        }),
        userMembers: PropTypes.shape({
          count: PropTypes.number,
          edges: PropTypes.arrayOf(
            PropTypes.shape({
              node: PropTypes.shape({
                id: PropTypes.string,
                portfolio: PropTypes.shape({
                  id: PropTypes.string,
                  title: PropTypes.string,
                  active: PropTypes.shape({
                    start: PropTypes.number,
                    finish: PropTypes.number,
                  }),
                }),
              }),
            }),
          ),
        }),
      }),
    ),
    pageInfo: PropTypes.shape({
      endCursor: PropTypes.string,
      hasNextPage: PropTypes.bool,
      hasPreviousPage: PropTypes.bool,
      startCursor: PropTypes.string,
    }),
  }).isRequired,
};
